import {
  SET_ALL_ORGANISATIONS,
  SET_ORGANISATIONS_LOADING,
  SET_PRESCHOOL_STATS,
} from "../actions/types";

const initialState = {
  loading: false,
};

const pedagogyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ALL_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
        loading: false,
      };
    case SET_PRESCHOOL_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    default:
      return state;
  }
};

export default pedagogyReducer;
