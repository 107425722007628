import React from 'react';
import TextFieldGroup from './TextFieldGroup';
import { Feather } from 'react-web-vector-icons';

export default function Link({
  name,
  description,
  url,
  onChange,
  onClick,
  disabled,
  linkIndex
}) {
  return (
    <div className="paragrafForm">
      <h4>
        Länk {linkIndex + 1}{' '}
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Feather name="external-link" color="black" size={18} />
        </a>{' '}
        <button
          type="button"
          className="btn btn-outline-warning btn-sm"
          onClick={() => onClick(linkIndex)}
        >
          <Feather
            name="trash-2"
            size={12}
            color="#ffc107"
            className="text-warning"
          />{' '}
          Ta bord
        </button>
      </h4>
      <TextFieldGroup
        name="name"
        placeholder="Namn"
        value={name}
        info="Namn eller titeln"
        type="text"
        disabled={disabled}
        onChange={e => onChange(e, linkIndex, 'name')}
      />
      <TextFieldGroup
        name="url"
        placeholder="url (länk), https://..."
        value={url}
        info="url (länk)"
        type="text"
        disabled={disabled}
        onChange={e => onChange(e, linkIndex, 'url')}
      />
      <textarea
        className="form-control form-control-lg"
        placeholder="Beskrivning"
        rows="3"
        value={description}
        onChange={e => onChange(e, linkIndex, 'description')}
      ></textarea>
    </div>
  );
}
