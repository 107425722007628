import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import axios from "axios";
import TextFieldGroup from "../common/TextFieldGroup";
import { Feather } from "react-web-vector-icons";
import jwt_decode from "jwt-decode";

export default function ResetPassword({ match }) {
  const token = match.params.token;

  const decoded = jwt_decode(token);
  const id = decoded.id;

  const current_time = Date.now() / 1000;
  const expired = decoded.exp < current_time;

  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [completed, setComplited] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (disabled) {
      const form = {
        password: password,
        password2: password2
      };
      axios
        .post(`users/resetpassword/${id}/${token}`, { ...form })
        .then(res => setComplited(true))
        .catch(err => {
          setErrors(err.response.data);
          setDisabled(false);
        });
    }
  }, [disabled]);

  let output;

  if (expired) {
    output = (
      <div className="container">
        <h1>Den tillfälliga länken har upphört.</h1>
        <div style={{ height: "40px" }}></div>
      </div>
    );
  } else if (!expired && !completed) {
    output = (
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-8">
            <form
              onSubmit={e => {
                e.preventDefault();
                setDisabled(true);
              }}
            >
              <TextFieldGroup
                name="password"
                placeholder="Nytt lösenord"
                value={password}
                info="Skriv ditt nya lösenord"
                type="password"
                disabled={disabled}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                error={errors.password}
              />
              <TextFieldGroup
                name="password2"
                placeholder="Upprepa lösenord"
                value={password2}
                info="Upprepa ditt nya lösenord"
                type="password"
                disabled={disabled}
                onChange={e => {
                  setPassword2(e.target.value);
                }}
                error={errors.password2}
              />

              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm btn-block"
                  disabled={disabled}
                >
                  Återställ lösenord{" "}
                  <Feather name="rotate-cw" color="white" size={12} />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div style={{ height: "40px" }}></div>
      </div>
    );
  } else if (!expired && completed) {
    output = (
      <div className="container">
        <h1>Ditt lösenord är återställt.</h1>
        <div style={{ height: "40px" }}></div>
      </div>
    );
  }

  return output;
}
