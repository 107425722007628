import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const YesNoQuestionField = ({ question, id, value, onChange, error }) => {
  return (
    <div>
      <div className="form-group">
        <label htmlFor={id}>{question}</label>
        <div
          className={classnames({
            error: error
          })}
        >
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id={id}
              name={id + 'yes'}
              value="1"
              checked={value === "1"}
              onChange={onChange}
            />
            <label className="form-check-label" htmlFor={id + 'yes'}>
              Ja
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name={id + 'no'}
              id={id}
              value="0"
              checked={value === "0"}
              onChange={onChange}
            />
            <label className="form-check-label" htmlFor={id + 'no'}>
              Nej
            </label>
          </div>
        </div>
        {error && <div className="error-feedback">{error}</div>}
      </div>
    </div>
  );
};

YesNoQuestionField.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default YesNoQuestionField;
