import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import AdminRoute from "./AdminRoute";

import NotFound from "../layout/NotFound";

import Dashboard from "../dashboard/Dashboard";

import Main from "../layout/Main";
import Login from "../auth/Login";
import ComputingThining from "../pedagogicalAreas/ComputingThinking";
import Support from "../support/Support";
import Privacy from "../privacy/Privacy";
import PedagogyTree from "../admin/pedagogyTree/PedagogyTree";
import NewPedagogyItem from "../admin/pedagogyTree/NewPedagogyItem";
import NewOrganisationItem from "../admin/organisation/NewOrganisationItem";
import PreschoolOrganisation from "../admin/organisation/PreschoolOrganisation";
import resetPassword from "../fokusforskola/ResetPassword";
import UpdateAge from "../admin/activities/UpdateAge";
import PreschoolDetail from "../admin/organisation/PreschoolDetail";

//import Preschool from '../admin/organisation/Preschool';
import Section from "../admin/organisation/Section";

const AppRouter = () => (
  <Router>
    <Switch>
      <AdminRoute path="/dashboard" component={Dashboard} exact={true} />
      <AdminRoute
        path="/pedagogicalarea/new"
        component={NewPedagogyItem}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid"
        component={PedagogyTree}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/new"
        component={NewPedagogyItem}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/:centralcontentid"
        component={PedagogyTree}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/:centralcontentid/new"
        component={NewPedagogyItem}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/:centralcontentid/:milestoneid"
        component={PedagogyTree}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/:centralcontentid/:milestoneid/:activityid"
        component={PedagogyTree}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/new"
        component={NewPedagogyItem}
        exact={true}
      />
      <AdminRoute
        path="/pedagogicalarea/:pedagogicalareaid/:centralcontentid/new"
        component={NewPedagogyItem}
        exact={true}
      />
      <AdminRoute
        path="/organisation/new"
        component={NewOrganisationItem}
        exact={true}
      />
      <AdminRoute
        path="/organisation/:preschoolorganisationid"
        component={PreschoolOrganisation}
        exact={true}
      />
      <AdminRoute
        path="/organisation/:preschoolorganisationid/new"
        component={NewOrganisationItem}
        exact={true}
      />
      <AdminRoute
        path="/organisation/:preschoolorganisationid/:preschoolid"
        component={PreschoolDetail}
        exact={true}
      />
      <AdminRoute
        path="/activities/updateage"
        component={UpdateAge}
        exact={true}
      />
      {/*<AdminRoute
        path="/organisation/:preschoolorganisationid/preschoolid"
        component={Preschool}
        exact={true}
      />*/}

      <AdminRoute
        path="/organisation/:preschoolorganisationid/:preschoolid/new"
        component={NewOrganisationItem}
        exact={true}
      />
      <AdminRoute
        path="/organisation/:preschoolorganisationid/:preschoolid/:sectionid"
        component={Section}
        exact={true}
      />

      <PublicRoute path="/" component={Login} exact={true} />
      <PublicRoute
        path="/digitalkompetens"
        component={ComputingThining}
        exact={true}
      />
      <PublicRoute path="/support" component={Support} exact={true} />
      <PublicRoute path="/privacy" component={Privacy} exact={true} />
      <PublicRoute path="/login" component={Login} exact={true} />
      <PublicRoute
        path="/fokusforskola/users/resetpassword/:token"
        component={resetPassword}
        exact={true}
      />
      <PublicRoute component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
