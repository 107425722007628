import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import Footer from "../layout/Footer";
import Showcase from "../layout/Showcase";

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <div>
    {/* <Showcase /> */}
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
    <Footer />
  </div>
);

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(PublicRoute);
