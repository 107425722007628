import {
  GET_EMPLOYEE,
  EMPLOYEE_LOADING,
  CLEAR_CURRENT_EMPLOYEE
} from '../actions/types';
const initialState = {
  employee: null,
  employees: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
        loading: false
      };
    case CLEAR_CURRENT_EMPLOYEE:
      return {
        ...state,
        employee: null
      };
    default:
      return state;
  }
}
