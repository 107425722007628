import React from "react";
import TextFieldGroup from "./TextFieldGroup";
import { Feather } from "react-web-vector-icons";

export default function Paragraf({
  title,
  text,
  onChange,
  onClick,
  disabled,
  paragrafIndex,
  localeIndex,
  type,
}) {
  return (
    <div className="paragrafForm">
      <h4>
        Stycke {paragrafIndex + 1}{" "}
        {!type && (
          <button
            type="button"
            className="btn btn-outline-warning btn-sm"
            onClick={() => onClick(paragrafIndex)}
          >
            <Feather
              name="trash-2"
              size={12}
              color="#ffc107"
              className="text-warning"
            />{" "}
            Ta bord
          </button>
        )}
      </h4>
      <TextFieldGroup
        name="title"
        placeholder="Titel"
        value={title}
        info="Ej obligatoriskt, skriv en titel."
        type="text"
        disabled={disabled}
        onChange={(e) =>
          !type
            ? onChange(e, paragrafIndex, "title")
            : onChange(e, paragrafIndex, "title", localeIndex)
        }
      />
      <textarea
        className="form-control form-control-lg"
        placeholder="Text"
        rows="3"
        value={text}
        onChange={(e) =>
          !type
            ? onChange(e, paragrafIndex, "text")
            : onChange(e, paragrafIndex, "text", localeIndex)
        }
      ></textarea>
    </div>
  );
}
