import React from 'react';
import { Feather } from 'react-web-vector-icons';
import DisplayLink from '../../common/DisplayLink';

const uuidv4 = require('uuid/v4');

const Activity = ({
  id,
  pedagogicalAreaId,
  centralContentId,
  milestoneId,
  pedagogy
}) => {
  const pedagogicalArea = pedagogy.filter(item => {
    return item._id === pedagogicalAreaId;
  })[0];

  const centralContent = pedagogicalArea.centralcontent.filter(
    item => item._id === centralContentId
  )[0];

  const mileStone = centralContent.milestones.filter(item => {
    return item._id === milestoneId;
  })[0];

  const activity = mileStone.activities.filter(item => {
    return item._id === id;
  })[0];

  const links = activity.links.map(item => {
    return <DisplayLink link={item} key={uuidv4()} />;
  });

  const locale = activity.locales.filter(item => item.locale === 'en')[0]

  const mainPicture = activity.pictures.length > 0 && (
    <img
      src={`https://api.edchild.com/${activity.pictures[0].big.path}`}
      className="img-fluid"
      alt={activity.name}
    />
  );

  const images = activity.pictures.shift();

  const pictureGallery = () => {
    const galleryItems = images.map(item => {
      return (
        <img
          src={`https://api.edchild.com/${item.big.path}`}
          className="img-fluid"
          alt={activity.name}
        />
      );
    });
    return <div>{galleryItems}</div>;
  };

  return (
    <div>
      {!!activity.pictures.length && mainPicture}
      <h1 className="adminTitle">
        Aktivitet: {activity.name}{' '}
        <Feather name="edit" color="aliceblue" size={18} />
      </h1>
      {!!images.length && pictureGallery}
      <p>{activity.comment}</p>
      {!!activity.links.length && (
        <div>
          <h2 className="adminSubTitle">Länkar</h2>
          {links}
        </div>
      )}
      <div style={{ height: '20px' }}></div>
      <h1 className="adminTitle">
        Aktivitet: {locale.name}{' '}
        <Feather name="edit" color="aliceblue" size={18} />
      </h1>
      {!!images.length && pictureGallery}
      <p>{locale.comment}</p>
      {!!activity.links.length && (
        <div>
          <h2 className="adminSubTitle">Länkar</h2>
          {links}
        </div>
      )}
      <div style={{ height: '20px' }}></div>
    </div>
  );
};

export default Activity;
