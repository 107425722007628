import React from 'react';
import { Link } from 'react-router-dom';

export default function CardAdd({ url }) {
  return (
    <div className="col-auto mb-3">
      <div className="card bg-dark text-white" style={{ width: '18rem' }}>
        <div className="card-header">Lägg till en förskola</div>
        <Link to={url}>
          <img
            src="/images/1464710523.png"
            className="card-img"
            alt="Lägg till en förskola"
            style={{ objectFit: 'cover' }}
          />
          <div className="card-img-overlay"></div>
        </Link>
      </div>
    </div>
  );
}
