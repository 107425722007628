import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrganisationForm from '../forms/OrganisationForm';

export class NewOrganisationItem extends Component {
  render() {
    let parentId;
    let action = 'preschoolorganisation';

    if (this.props.match.params.preschoolorganisationid) {
      parentId = this.props.match.params.preschoolorganisationid;
      action = 'preschool';
    }

    if (this.props.match.params.preschoolid) {
      parentId = this.props.match.params.preschoolid;
      action = 'section';
    }

    return (
      <div>
        <div className="container">
          <OrganisationForm
            action={action}
            parentId={parentId}
            history={this.props.history}
            params={this.props.match.params}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewOrganisationItem);
