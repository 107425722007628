export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ENROLL_PRESCHOOL = "ENROLL_PRESCHOOL";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const EMPLOYEE_LOADING = "EMPLOYEE_LOADING";
export const EMPLOYEE_NOT_FOUND = "EMPLOYEE_NOT_FOUND";
export const CLEAR_CURRENT_EMPLOYEE = "CLEAR_CURRENT_EMPLOYEE";
export const GET_EMPLOYEES = "GET_EMPLOYEES";

export const GET_SURVEY = "GET_SURVEY";
export const SURVEY_LOADING = "SURVEY_LOADING";
export const SET_ANSWERS = "SET_ANSWERS";
export const REGISTER_ANSWERS = "REGISTER_ANSWERS";
export const GET_SURVEY_RESULTS = "GET_SURVEY_RESULTS";

export const SET_PEDAGOGICAL_AREAS = "SET_PEDAGOGICAL_AREAS";
export const SET_PEDAGOGY_LOADING = "SET_PEDAGOGY_LOADING";

export const SET_ORGANISATIONS_LOADING = "SET_ORGANISATIONS_LOADING";
export const SET_ALL_ORGANISATIONS = "SET_ALL_ORGANISATIONS";
export const SET_PRESCHOOL_STATS = "SET_PRESCHOOL_STATS";

export const SET_ACTIVITY_LIST = "SET_ACTIVITY_LIST";
export const SET_CURRENT_DISPLAY_ACTIVITY = "SET_CURRENT_DISPLAY_ACTIVITY";

export const SET_ACTIVITY_STATS = "SET_ACTIVITY_STATS";
export const SET_USER_STATS = "SET_USER_STATS";
export const SET_PRIVATE_USER_STATS = "SET_PRIVATE_USER_STATS";
