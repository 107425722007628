import React, { Component } from 'react';
import axios from 'axios';
import { Feather } from 'react-web-vector-icons';

export default class DisplayLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null
    };
  }
  componentDidMount() {
    axios
      .post('utils/linkpreview', { link: this.props.link.url })
      .then(link => {
        this.setState({ link: link.data });
      })
      .catch(err => console.log(err));
  }
  render() {
    let link = (
      <div>
        <h6>Laddar {this.props.link.name}</h6>
      </div>
    );
    if (this.state.link) {
      link = (
        <div className="card mb-3">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                src={
                  !!this.state.link.images
                    ? this.state.link.images[0]
                    : '/images/logoHpts.png'
                }
                className="card-img"
                alt={this.state.link.title}
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">
                  {!!this.state.link.title
                    ? this.state.link.title
                    : this.props.link.name}
                </h5>
                <p className="card-text">
                  {!!this.state.link.description
                    ? this.state.link.description
                    : this.props.link.description}
                </p>
                <p className="card-text">
                  <small className="text-muted">
                    <a
                      href={this.state.link.url}
                      className="card-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gå till{' '}
                      {!!this.state.link.title
                        ? this.state.link.title
                        : this.props.link.name}{' '}
                      <Feather name="external-link" color="black" size={13} />
                    </a>
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div>{link}</div>;
  }
}
