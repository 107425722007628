import React from 'react';
import PropTypes from 'prop-types';
//import { connect } from 'react-redux';
import classnames from 'classnames';
//import { setAnswers } from '../../actions/surveyActions';

const TextQuestionField = ({
  question,
  id,
  type,
  value,
  onChange,
  placeHolder,
  error
}) => {
  return (
    <div>
      <div className="form-group">
        <label htmlFor={id}>{question}</label>
        <div>
          <input
            type={type}
            className={classnames('form-control', {
              'is-invalid': error ? error : null
            })}
            id={id}
            aria-describedby="question"
            placeholder={placeHolder ? placeHolder : 'Ditt svar'}
            value={value}
            onChange={onChange}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
};

TextQuestionField.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  error: PropTypes.string
};

export default TextQuestionField;
