import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveOrganisation } from '../../../actions/organisationsActions';
import TextFieldGroup from '../../common/TextFieldGroup';
import { Feather } from 'react-web-vector-icons';
import AdressField from '../../common/AdressField';
import PhoneField from '../../common/PhoneField';
import EmailField from '../../common/EmailField';
const uuidv4 = require('uuid/v4');

export class OrganisationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},

      organisationType: {},
      parentId: null,
      action: null,
      dataBacup: {}
    };
  }

  componentDidMount() {
    if (!!this.props.data) {
      let data = this.props.data;
      data.parentId = this.props.parentId;
      this.setState((prevState, props) => ({
        data: this.props.data,
        dataBacup: JSON.parse(JSON.stringify(this.props.data)),
        organisationType: this.props.organisationType,
        parentId: this.props.parentId,
        action: this.props.action
      }));
    } else {
      this.setState((prevState, props) => ({
        data: {
          name: '',
          adress: [
            {
              name: '',
              streetadress1: '',
              streetadress2: '',
              postcode: '',
              city: '',
              country: 'Sverige'
            }
          ],
          phones: [
            {
              name: '',
              number: ''
            }
          ],
          emails: [
            {
              name: '',
              email: ''
            }
          ],
          companyregistrationnumber: '',
          homepage: '',
          parentId: this.props.parentId
        },
        organisationType: this.props.organisationType,
        parentId: this.props.parentId,
        action: this.props.action
      }));
    }
  }

  onSubmit = e => {
    e.preventDefault();

    this.props
      .saveOrganisation(this.state.data, this.props.action)
      .then(result => {
        switch (this.props.action) {
          case 'preschoolorganisation':
            this.props.history.push(`/organisation/${result._id}`);
            break;
          case 'preschool':
            this.props.history.push(
              `/organisation/${this.props.params.preschoolorganisationid}`
            );
            break;
          case 'section':
            this.props.history.push(
              `/organisation/${this.props.params.preschoolorganisationid}`
            );
            break;

          default:
            this.props.history.push(
              `/organisation/${this.props.params.preschoolorganisationid}`
            );
            break;
        }
        !!this.state.data._id && this.closeOneModal('myModal');
      })
      .catch(err => console.log(err));
  };

  closeOneModal = modalId => {
    // get modal
    const modal = document.getElementById(modalId);

    // change state like in hidden modal
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');

    // get modal backdrop
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove opened modal backdrop
    document.body.removeChild(modalBackdrops[0]);
    document.body.classList.remove('modal-open');

    modal.setAttribute('modal', 'hide');
  };

  onAddElement(element) {
    switch (element) {
      case 'adress':
        let adresses = this.state.data.adress;

        adresses.push({
          name: '',
          streetadress1: '',
          streetadress2: '',
          postcode: '',
          city: '',
          country: 'Sverige'
        });
        this.setState((prevState, props) => ({
          data: {
            ...prevState.data,
            adress: adresses
          }
        }));
        break;
      case 'phone':
        let phones = this.state.data.phones;

        phones.push({
          name: '',
          number: ''
        });
        this.setState((prevState, props) => ({
          data: {
            ...prevState.data,
            phones: phones
          }
        }));
        break;

      case 'email':
        let emails = this.state.data.emails;

        emails.push({
          name: '',
          email: ''
        });
        this.setState((prevState, props) => ({
          data: {
            ...prevState.data,
            emails: emails
          }
        }));
        break;

      default:
        break;
    }
  }

  onReset = () => {
    const bacup = JSON.parse(JSON.stringify(this.state.dataBacup));

    this.setState((prevState, props) => ({
      data: bacup
    }));
  };

  onChangeName = e => {
    const name = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        name: name
      }
    }));
  };

  onChangeHomePage = e => {
    const homePage = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        homepage: homePage
      }
    }));
  };

  onChangeCompanyRegistrationNumber = e => {
    const reg = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        companyregistrationnumber: reg
      }
    }));
  };

  onChangeArray = (e, index, target, element) => {
    const arrayToUpdate = [...this.state.data[element]];
    arrayToUpdate[index][target] = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        [element]: arrayToUpdate
      }
    }));
  };

  render() {
    let title;
    if (!!this.state.data._id) {
      title = `Redigera ${this.state.data.name}`;
    } else {
      switch (this.props.action) {
        case 'preschoolorganisation':
          title = 'Ny organisation';
          break;
        case 'preschool':
          title = 'Ny förskola';
          break;
        case 'section':
          title = 'Ny avdelning';
          break;

        default:
          title = 'Ny organisation';
          break;
      }
    }

    let companyregistrationnumber = null;
    if (this.props.action === 'preschoolorganisation') {
      companyregistrationnumber = (
        <div>
          <div className="adminTitle mb-2">Organisationsnummer</div>
          <TextFieldGroup
            name="companyregistrationnumber"
            placeholder="Organisationsnummer"
            value={
              !!this.state.data.companyregistrationnumber
                ? this.state.data.companyregistrationnumber
                : ''
            }
            info="organisationsnummer"
            type="text"
            disabled={this.state.disabled}
            onChange={e => this.onChangeCompanyRegistrationNumber(e)}
          />
        </div>
      );
    }

    const adresses =
      this.state.data.adress &&
      this.state.data.adress.map((item, index) => {
        return (
          <AdressField
            adress={item}
            index={index}
            onChange={this.onChangeArray}
            key={new uuidv4()}
          />
        );
      });

    const phones =
      this.state.data.phones &&
      this.state.data.phones.map((item, index) => {
        return (
          <PhoneField
            phone={item}
            index={index}
            onChange={this.onChangeArray}
            key={new uuidv4()}
          />
        );
      });

    const emails =
      this.state.data.emails &&
      this.state.data.emails.map((item, index) => {
        return (
          <EmailField
            email={item}
            index={index}
            onChange={this.onChangeArray}
            key={new uuidv4()}
          />
        );
      });

    return (
      <div style={{ padding: '20px' }}>
        <h1>{title}</h1>
        <form onSubmit={e => this.onSubmit(e)}>
          <div className="adminTitle mb-2">Namn</div>
          <TextFieldGroup
            name="namn"
            placeholder="Namn"
            value={!!this.state.data.name ? this.state.data.name : ''}
            info="Namn"
            type="text"
            disabled={this.state.disabled}
            onChange={e => this.onChangeName(e)}
          />

          {companyregistrationnumber}

          <div className="container bg-light pb-2 rounded">
            {adresses}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => this.onAddElement('adress')}
            >
              <Feather name="plus-square" color="white" size={12} /> Lägg till
              en adress
            </button>
          </div>

          <div className="container bg-light pb-2 rounded">
            {phones}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => this.onAddElement('phone')}
            >
              <Feather name="plus-square" color="white" size={12} /> Lägg till
              ett telefonnummer
            </button>
          </div>

          <div className="container bg-light pb-2 rounded">
            {emails}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => this.onAddElement('email')}
            >
              <Feather name="plus-square" color="white" size={12} /> Lägg till
              en mailadress
            </button>
          </div>

          <div className="adminTitle mb-2">Hemsidan</div>
          <TextFieldGroup
            name="homepage"
            placeholder="Hemsida"
            value={!!this.state.data.homepage ? this.state.data.homepage : ''}
            info="Hemsida"
            type="text"
            disabled={this.state.disabled}
            onChange={e => this.onChangeHomePage(e)}
          />

          <div className="py-2 row">
            <div className="col-lg-8 ml-auto">
              <button
                type="submit"
                className="btn btn-primary btn-sm btn-block"
              >
                <Feather name="save" color="white" size={12} /> Spara
              </button>
            </div>
            <div className="col-lg-2 ml-auto">
              <button
                type="button"
                className="btn btn-warning btn-sm btn-block"
                onClick={() => {
                  this.onReset();
                }}
              >
                <Feather name="x-square" color="white" size={12} /> Återställ
              </button>
            </div>
            <div className="col-lg-2 ml-auto">
              <button
                data-dismiss="modal"
                aria-label="Close"
                className="btn btn-danger btn-sm btn-block"
              >
                <Feather name="x-square" color="white" size={12} /> Avbryt
              </button>
            </div>
          </div>
        </form>
        <div style={{ height: '40px' }}></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = { saveOrganisation };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationForm);
