import React from 'react';
import Sortable from 'react-sortablejs';
import PropTypes from 'prop-types';
import Icon from 'react-web-vector-icons';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

const SortableList = ({ items, onChange }) => {
  let sortable = null; // sortable instance

  // function preventDefault(e) {
  //   e.preventDefault();
  // }

  function disableScroll() {
    disablePageScroll();
  }
  function enableScroll() {
    enablePageScroll();
  }

  const listItems = items.map((val, index) => (
    <div
      key={val.value}
      data-id={val.value}
      className="sortlist-item border border-light rounded"
      onTouchStart={disableScroll}
      onTouchEnd={enableScroll}
    >
      <div className="sortlist-inner">
        <div className="sortlist-inner-icon">
          <Icon name="ios-menu" font="Ionicons" color="darkgrey" size={20} />
        </div>
        <div>
          {index + 1}. {val.option}
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <Sortable
        // Sortable options (https://github.com/RubaXa/Sortable#options)
        options={{}}
        // [Optional] Use ref to get the sortable instance
        // https://facebook.github.io/react/docs/more-about-refs.html#the-ref-callback-attribute
        ref={c => {
          if (c) {
            sortable = c.sortable;
          }
        }}
        // [Optional] A tag or react component to specify the wrapping element. Defaults to "div".
        // In a case of a react component it is required to has children in the component
        // and pass it down.
        tag="div"
        // [Optional] The onChange method allows you to implement a controlled component and keep
        // DOM nodes untouched. You have to change state to re-render the component.
        // @param {Array} order An ordered array of items defined by the `data-id` attribute.
        // @param {Object} sortable The sortable instance.
        // @param {Event} evt The event object.
        onChange={(order, sortable, evt) => {
          onChange(order);
        }}
      >
        {listItems}
      </Sortable>
    </div>
  );
};

SortableList.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default SortableList;
