import React from 'react';
import Icon from 'react-web-vector-icons';

function Showcase() {
  return (
    <div>
      <div className="showcase">
        <div className="showcase-inner">
          <p>17.000 eller 70.000 ord?</p>
        </div>
        <div className="downArrow">
          <Icon
            name="ios-arrow-down"
            font="Ionicons"
            color="rgb(83, 83, 83)"
            size={'6vw'}
          />
        </div>
      </div>
      <div className="title">edChild</div>
    </div>
  );
}
export default Showcase;
