import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'react-web-vector-icons';

export default function ComputingThinking() {
  return (
    <div className="idea">
      <div className="idea-inner">
        <div className="survey-title">Digital kompetens</div>
        <p>
          Här är det lite tomt. Vi behöver fylla i med lite text. Det kommer
          snart.
        </p>
        <Link to="/">
          <Icon
            name="ios-arrow-round-back"
            font="Ionicons"
            color="darkgrey"
            size={20}
          />
        </Link>
      </div>
    </div>
  );
}
