import {
  GET_SURVEY,
  SURVEY_LOADING,
  SET_ANSWERS,
  REGISTER_ANSWERS,
  GET_SURVEY_RESULTS
} from '../actions/types';

const answered = !!JSON.parse(localStorage.getItem('survey'));

const initialState = {
  survey: null,
  answers: [],
  loading: false,
  answered: answered
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SURVEY_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SURVEY:
      const questions = action.payload.questions;
      const answers = questions.map(question => {
        return {
          id: question._id,
          type: question.questionType,
          answer: question.defaultValue
        };
      });
      return {
        ...state,
        survey: action.payload,
        answers: answers,
        loading: false
      };
    case SET_ANSWERS:
      return {
        ...state,
        answers: action.payload
      };
    case GET_SURVEY_RESULTS:
      return {
        ...state,
        surveyResults: action.payload,
        loading: false
      }  
    case REGISTER_ANSWERS:
      return {
        ...state,
        answers: null,
        answered: true
      };
    default:
      return state;
  }
}
