import React from 'react';

export default function Footer() {
  return (
    <div className="navbar fixed-bottom bg-dark navbar-dark">
      <small className="text-light text-center">
        Copyright &copy; {new Date().getFullYear()} edChild
      </small>
    </div>
  );
}
