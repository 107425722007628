import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";

import PedagogicalArea from "./PedagogicalArea";
import CentralContent from "./CentralContent";
import MileStone from "./MileStone";
import Activity from "./Activity";
import ScaleLoader from "react-spinners/ScaleLoader";

class PedagogyTree extends Component {
  render() {
    let pedagogicalAreaComponent = (
      <PedagogicalArea
        id={this.props.match.params.pedagogicalareaid}
        pedagogicalAreas={this.props.pedagogy.pedagogicalAreas}
      />
    );

    let centralContentComponent = (
      <CentralContent
        id={this.props.match.params.centralcontentid}
        pedagogicalAreaId={this.props.match.params.pedagogicalareaid}
        pedagogy={this.props.pedagogy.pedagogicalAreas}
      />
    );

    let mileStoneComponent = (
      <MileStone
        id={this.props.match.params.milestoneid}
        pedagogicalAreaId={this.props.match.params.pedagogicalareaid}
        centralContentId={this.props.match.params.centralcontentid}
        pedagogy={this.props.pedagogy.pedagogicalAreas}
      />
    );

    let activityComponent = (
      <Activity
        id={this.props.match.params.activityid}
        pedagogicalAreaId={this.props.match.params.pedagogicalareaid}
        centralContentId={this.props.match.params.centralcontentid}
        milestoneId={this.props.match.params.milestoneid}
        pedagogy={this.props.pedagogy.pedagogicalAreas}
      />
    );

    let dashboardContent;

    dashboardContent = (
      <div>
        <div className="container">
          {!this.props.match.params.centralcontentid &&
            pedagogicalAreaComponent}
          {!this.props.match.params.milestoneid &&
            this.props.match.params.centralcontentid &&
            centralContentComponent}
          {!this.props.match.params.activityid &&
            this.props.match.params.milestoneid &&
            mileStoneComponent}
          {this.props.match.params.activityid && activityComponent}
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    );

    return this.props.pedagogy.loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: window.innerHeight,
        }}
      >
        <ScaleLoader
          height={35}
          width={4}
          radius={2}
          margin={2}
          loading={true}
          color={"#FCB817"}
        />
        <div style={{ color: "#FCB817", fontSize: 12 }}>Laddar</div>
      </div>
    ) : (
      dashboardContent
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pedagogy: state.pedagogy,
  organisations: state.organisations.organisations,
});

export default connect(mapStateToProps, {})(PedagogyTree);
