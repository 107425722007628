import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextQuestionField from './TextQuestionField';
import SortQuestionField from './SortQuestionField';
import YesNoQuestionField from './YesNoQuestionField';
import RangeQuestionField from './RangeQuestionField';
import ChooseOneQuestionField from './ChooseOneQuestionField';
import TextMultipleQuestionField from './TextMultipleQuestionField';
import { setAnswers, registerAnswers } from '../../actions/surveyActions';
import Spinner from '../common/Spinner';

class Survey extends Component {
  submitHandler = e => {
    e.preventDefault();
    this.props.registerAnswers(this.props.survey);
  };

  onChange = e => {
    let newAnswer = e.target.value;
    let answers = this.props.survey.answers.map(answer => {
      if (answer.id === e.target.id) {
        answer.answer = newAnswer;
      }
      return answer;
    });
    this.props.setAnswers(answers);
  };

  render() {
    const { loading, survey, answered } = this.props.survey;

    let surveyContent;
    let surveyTitle;

    if (loading || survey === null) {
      surveyTitle = null;
      surveyContent = <Spinner />;
    } else if (answered && survey) {
      surveyTitle = <div className="survey-title">{survey.name}</div>;
      surveyContent = <div>Tack för din medverkan!</div>;
    } else {
      surveyTitle = <div className="survey-title">{survey.name}</div>;
      const sendBtn = (
        <input
          type="submit"
          className="btn btn-outline-info"
          onClick={this.submitHandler}
        />
      );

      const questions = survey.questions.map((question, index) => {
        let questionWrapper;
        switch (question.questionType) {
          case 'text':
            questionWrapper = (
              <TextQuestionField
                question={question.question}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                placeHolder={
                  question.placeHolder ? question.placeHolder : 'Ditt svar'
                }
                error={this.props.errors[question._id]}
              />
            );
            break;
          case 'email':
            questionWrapper = (
              <TextQuestionField
                question={question.question}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                placeHolder={
                  question.placeHolder
                    ? question.placeHolder
                    : 'Din epost adress'
                }
                error={this.props.errors[question._id]}
              />
            );
            break;
          case 'sort':
            questionWrapper = (
              <SortQuestionField
                instructions={question.instructions}
                question={question.question}
                options={question.options}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                error={this.props.errors[question._id]}
              />
            );
            break;
          case 'yesOrNo':
            questionWrapper = (
              <YesNoQuestionField
                question={question.question}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                error={this.props.errors[question._id]}
              />
            );
            break;
          case 'chooseone':
            questionWrapper = (
              <ChooseOneQuestionField
                question={question.question}
                options={question.options}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                error={this.props.errors[question._id]}
              />
            );
            break;
          case 'range':
            questionWrapper = (
              <RangeQuestionField
                question={question.question}
                options={question.options}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                error={this.props.errors[question._id]}
              />
            );
            break;
          case 'textmultiple':
            questionWrapper = (
              <TextMultipleQuestionField
                question={question.question}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                placeHolder={
                  question.placeHolder ? question.placeHolder : 'Ditt svar'
                }
                error={this.props.errors[question._id]}
              />
            );
            break;
          default:
            questionWrapper = (
              <TextQuestionField
                question={question.question}
                key={question._id}
                index={question.index}
                id={question._id}
                type={question.questionType}
                value={this.props.survey.answers[index].answer}
                onChange={this.onChange}
                placeHolder="Ditt svar"
                error={this.props.errors[question._id]}
              />
            );
        }
        return (
          <div key={question._id}>
            <div>{questionWrapper}</div>
            {index !== survey.questions.length - 1 && <hr />}
          </div>
        );
      });

      surveyContent = (
        <div>
          {questions}
          {sendBtn}
        </div>
      );
    }

    return (
      <div>
        {surveyTitle}
        {surveyContent}
      </div>
    );
  }
}

Survey.propTypes = {
  survey: PropTypes.object.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  survey: state.survey,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { setAnswers, registerAnswers }
)(Survey);
