import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Feather } from "react-web-vector-icons";
import { logoutUser } from "../../../actions/authActions";
import { getPedagogicalAreas } from "../../../actions/pedagogyActions";
import { getAllOrganisations } from "../../../actions/organisationsActions";
import BarLoader from "react-spinners/BarLoader";

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownMenuVisible: {
        pedagogy: false,
      },
    };
  }

  componentDidMount() {
    !this.props.pedagogy.pedagogicalAreas && this.props.getPedagogicalAreas();
    !this.props.organisations.organisations && this.props.getAllOrganisations();
  }

  toggleDropDownMenu = () => {
    this.setState((prevState) => ({
      dropdownMenuVisible: {
        pedagogy: !prevState.dropdownMenuVisible.pedagogy,
      },
    }));
  };

  logout = () => {
    this.props.logoutUser();
  };

  render() {
    const pedagogicalAreaItems = () => {
      return this.props.pedagogy.pedagogicalAreas ? (
        this.props.pedagogy.pedagogicalAreas.map((item) => {
          return (
            <Link
              className="dropdown-item"
              to={`/pedagogicalarea/${item._id}`}
              key={item._id}
            >
              {item.name}
            </Link>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 35,
          }}
        >
          <BarLoader
            loading={true}
            color={"#FCB817"}
            css={{ display: "inherit" }}
            width="90%"
            height={2}
          />
        </div>
      );
    };

    const organisationItems = () => {
      return this.props.organisations.organisations ? (
        this.props.organisations.organisations.map((item) => {
          return (
            <Link
              className="dropdown-item"
              to={`/organisation/${item._id}`}
              key={item._id}
            >
              {item.name}
            </Link>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 35,
          }}
        >
          <BarLoader
            loading={true}
            color={"#FCB817"}
            height={2}
            css={{ display: "inherit" }}
            width="90%"
          />
        </div>
      );
    };

    let navbar = <div></div>;
    if (
      // this.props.organisations &&
      // this.props.pedagogy &&
      // !this.props.pedagogy.loading &&
      // !this.props.organisations.loading
      true
    ) {
      navbar = (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/dashboard">
            <img
              src="/favicon-32x32.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt=""
            />{" "}
            edChild
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mr-auto">
              {/* <li className="nav-item active">
                <Link className="nav-link" to="/dashboard">
                  <Feather
                    name="home"
                    color="black"
                    size={14}
                    // style={{}}
                  />{" "}
                  Hem <span className="sr-only">(current)</span>
                </Link>
              </li> */}
              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle showCursor"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Läroplansområden
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {pedagogicalAreaItems()}
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to={`/pedagogicalarea/new`}>
                    <Feather
                      name="plus-square"
                      color="black"
                      size={14}
                      // style={{}}
                    />{" "}
                    Ny läroplansområde
                  </Link>
                </div>
              </li>

              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle showCursor"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Organisationer
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {organisationItems()}
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to={`/organisation/new`}>
                    <Feather
                      name="plus-square"
                      color="black"
                      size={14}
                      // style={{}}
                    />{" "}
                    Ny organisation
                  </Link>
                </div>
              </li>

              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle showCursor"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Aktiviteter
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link className="dropdown-item" to={`/activities/updateage`}>
                    <Feather
                      name="rotate-cw"
                      color="black"
                      size={14}
                      // style={{}}
                    />{" "}
                    Updatera åldern
                  </Link>
                </div>
              </li>
            </ul>
            <span className="navbar-text">
              <button
                type="button"
                className="btn btn-outline-danger  btn-sm"
                onClick={() => this.logout()}
              >
                <Feather
                  name="log-out"
                  color="#d9534f"
                  size={14}
                  // style={{}}
                />
              </button>
            </span>
          </div>
        </nav>
      );
    }

    return navbar;
  }
}

const mapStateToProps = (state) => ({
  pedagogy: state.pedagogy,
  organisations: state.organisations,
});

export default connect(mapStateToProps, {
  logoutUser,
  getPedagogicalAreas,
  getAllOrganisations,
})(NavBar);
