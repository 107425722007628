import axios from 'axios';
import {
  GET_ERRORS,
  GET_SURVEY,
  SURVEY_LOADING,
  SET_ANSWERS,
  REGISTER_ANSWERS,
  GET_SURVEY_RESULTS
} from './types';
//import { loginUser } from './authActions';

//Get survey
export const getSurvey = surveyId => dispatch => {
  dispatch(setSurveyLoading());
  axios
    .get('survey/' + surveyId)
    .then(res => {
      dispatch({
        type: GET_SURVEY,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

//Survey loading
export const setSurveyLoading = () => {
  return {
    type: SURVEY_LOADING
  };
};

export const setAnswers = answers => dispatch => {
  dispatch({
    type: SET_ANSWERS,
    payload: answers
  });
};

export const registerAnswers = survey => dispatch => {
  //console.log({ answers: [answers] });
  let payload = {
    answers: survey.answers,
    survey: survey.survey._id
  };
  axios
    .post('survey/saveanswers', payload)
    .then(res => {
      localStorage.setItem('survey', JSON.stringify(payload));
      dispatch({
        type: REGISTER_ANSWERS
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

//Get survey
export const getSurveyResults = surveyId => dispatch => {
  dispatch(setSurveyLoading());
  axios
    .get('survey/results/' + surveyId)
    .then(res => {
      dispatch({
        type: GET_SURVEY_RESULTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
