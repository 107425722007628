import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { clearCurrentEmployee } from "./actions/employeesActions";
import { Provider } from "react-redux";
import store from "./store";

import AppRouter from "./components/router/AppRouter";

import "./App.css";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://api.edchild.com/";

//axios.defaults.baseURL = "https://api.edchild.com/";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

//Check for auth token
if (localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //decode token and get user info & expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));

  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    store.dispatch(clearCurrentEmployee());
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}

export default App;
