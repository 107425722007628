import React from "react";
import { Link } from "react-router-dom";
import { Feather } from "react-web-vector-icons";
import PedagogyFormModal from "../forms/PedagogyFormModal";
import DisplayLink from "../../common/DisplayLink";
const uuidv4 = require("uuid/v4");

const MileStone = ({ id, pedagogicalAreaId, centralContentId, pedagogy }) => {
  const pedagogicalArea = pedagogy.filter((item) => {
    return item._id === pedagogicalAreaId;
  })[0];

  const centralContent = pedagogicalArea.centralcontent.filter(
    (item) => item._id === centralContentId
  )[0];

  const mileStone = centralContent.milestones.filter((item) => {
    return item._id === id;
  })[0];

  const milseStoneContent = mileStone.content.map((item) => {
    const title = item.title;
    const text = item.text;
    return (
      <div key={uuidv4()}>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    );
  });

  let activities = mileStone.activities.map((item) => {
    const name = item.name;
    return (
      <li key={uuidv4()}>
        <Link
          to={`/pedagogicalarea/${pedagogicalAreaId}/${centralContentId}/${id}/${item._id}`}
        >
          {name}
        </Link>
      </li>
    );
  });

  const links = mileStone.links.map((item) => {
    return <DisplayLink link={item} key={uuidv4()} />;
  });

  let locales = mileStone.locales
    .filter((lang) => lang.locale.languageCode !== "sv")
    .map((lang) => {
      const content = lang.content.map((item) => {
        const title = item.title;
        const text = item.text;
        return (
          <div key={uuidv4()}>
            <h4>{title}</h4>
            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
          </div>
        );
      });

      return (
        <div style={{ backgroundColor: "whitesmoke" }} key={uuidv4()}>
          <h2 className="adminSubTitle">
            Översättning på {lang.locale.name} (Kod "{lang.locale.languageCode}
            ")
          </h2>
          <div style={{ padding: 12 }}>
            <h3>{lang.name}</h3>
            <p>Kort namn: {lang.shortName}</p>
            {content}
            <h4>Kort beskrivning</h4>
            <p style={{ whiteSpace: "pre-line" }}>{lang.comment}</p>
          </div>
        </div>
      );
    });

  return (
    <div>
      <div
        className="modal fade bd-example-modal-xl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        id="myModal"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <PedagogyFormModal
              content={mileStone}
              subject="mileStone"
              parentId={centralContentId}
              modalName="myModal"
            />
          </div>
        </div>
      </div>
      <img
        src={pedagogicalArea.header}
        className="img-fluid"
        alt={pedagogicalArea.name}
      />
      <h1 className="adminTitle">
        Lärprocess: {mileStone.name}{" "}
        <button
          type="button"
          className="btn btn-outline-light btn-sm"
          data-toggle="modal"
          data-target=".bd-example-modal-xl"
        >
          <Feather name="edit" color="aliceblue" size={18} />
        </button>
      </h1>
      <h2>Beskrivning (lång)</h2>
      {milseStoneContent}
      <h2>Beskrivning (kort)</h2>
      {mileStone.comment}
      {!!mileStone.links.length && (
        <div>
          <h2 className="adminSubTitle">Länkar</h2>
          {links}
        </div>
      )}
      {locales}
      {/* <h2 className="adminSubTitle">Aktiviteter</h2> */}
      {/* <ul>{activities}</ul> */}
      <div style={{ height: "20px" }}></div>
    </div>
  );
};

export default MileStone;
