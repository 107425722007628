import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SortableList from '../common/SortableList';

const SortQuestionField = ({
  question,
  instructions,
  options,
  id,
  value,
  onChange,
  error
}) => {
  let onChangeHandler = items => {
    const result = {
      target: {
        value: items,
        id: id
      }
    };
    onChange(result);
  };

  let items = value.map(item => {
    const filteredOption = options.filter(option => {
      return option.value.toString() === item.toString();
    });
    return filteredOption[0];
    // return {option: filteredOption.}
  });

  return (
    <div>
      <div className="form-group">
        <label htmlFor={id}>
          {question}
          <br />
          {instructions && <small>{instructions}</small>}
        </label>

        <div
          className={classnames({
            error: error
          })}
        >
          <SortableList items={items} onChange={onChangeHandler} />
        </div>
      </div>
      {error && <div className="error-feedback">{error}</div>}
    </div>
  );
};

SortQuestionField.propTypes = {
  question: PropTypes.string.isRequired,
  instructions: PropTypes.string,
  options: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default SortQuestionField;
