import { SET_ACTIVITY_LIST, SET_CURRENT_DISPLAY_ACTIVITY } from '../actions/types';

const initialState = {
    allActivities: [],
    currentDisplayActivity: null
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVITY_LIST:
            return {
                ...state,
                allActivities: action.payload
            };
        case SET_CURRENT_DISPLAY_ACTIVITY:
            return {
                ...state,
                currentDisplayActivity: action.payload
            }
        default:
            return state;
    }
}
