import React, { Component } from 'react';

class Privacy extends Component {
  render() {
    return (
      <div>
        <h1 className="display-4 text-center">
          Fokus Förskola sekretesspolicy
        </h1>
        <div className="idea">
          <div>
            <div className="idea-inner">
              <p>
                När du använder Fokus Förskola anförtror du oss dina uppgifter.
                I den här sekretesspolicyn förklarar vi vilken data vi samlar
                in, varför vi samlar in den och hur den används. Den beskriver
                också dina rättigheter gentemot oss och hur du kan göra dina
                rättigheter gällande. Genom att använda Fokus Förskola,
                accepterar du vår sekretesspolicy och samtycker till vår
                behandling av dina personuppgifter. Du godkänner också att vi
                använder elektroniska kommunikationskanaler för att skicka
                information till dig. Det är viktigt att du läser och förstår
                vår sekretesspolicy innan du använder Fokus Förskola.
              </p>
              <h3>1. Information som vi samlar in</h3>
              <p>
                För att vi ska kunna tillhandahålla och förbättra Fokus Förskola
                för alla användare och för att du ska kunna använda och få
                kundsupport samlar vi in data som du uppger och data som vi får
                när du använder Fokus Förskola. Information som du uppger. Du
                kan direkt eller indirekt komma att ge oss information om dig
                själv på ett antal olika sätt, såsom när du först registrerar
                dig, när du skapar och kommunicerar, när du använder vår
                kundsupport eller på något annat sätt lämnar personuppgifter i
                samband med att du använder Fokus Förskola. Detta kan vara:
              </p>
              <ul>
                <li>
                  Ditt Fokus Förskola-konto. I samband med att du registrerar
                  dig och skapar ett Fokus Förskola-konto anger du din
                  mailadress.
                </li>
                <li>
                  Information som du skapar. Vi samlar även in innehåll som du
                  skapar och skickar när du använder Fokus Förskola-tjänsten.
                  Det kan röra sig om t.ex. aktiviteter, bilder, filmer eller
                  annat material som du publicerar på Fokus Förskola. Du kan
                  även välja att registrera namn, yrkesroll och arbetsplats.
                </li>
                <li>
                  Kundsupport. Om du använder vår kundsupport förser du oss med
                  information om din användning av Fokus Förskola såsom din
                  mailadress.
                </li>
              </ul>

              <p>
                Information vi samlar in när du använder våra tjänster. När du
                använder Fokus Förskola, kan vi samla in följande information:
              </p>
              <ul>
                <li>
                  Teknisk information. När du använder Fokus Förskola samlas
                  teknisk data i form av leveransrapporter, uppgifter om vilken
                  information du tar del av, vilken enhet du har, vilken
                  mobiltelefonmodell du använder och i vissa fall din IP-adress
                  automatiskt in.
                </li>
                <li>
                  Lokal lagring. Vi kan samla in och spara information
                  (inklusive personuppgifter) lokalt på din enhet med hjälp av
                  funktioner som webbläsarens lagringsutrymme (inklusive HTML 5)
                  och programcacher.
                </li>
                <li>
                  Cookies och liknande teknik. Vi använder olika tekniker för
                  att samla in och spara information när du använder vår
                  hemsida/appar. Det kan innebära att cookies eller liknande
                  tekniker används för att identifiera din webbläsare eller
                  enhet. En cookie är en liten fil som innehåller en
                  teckensträng som skickas till din enhet när du besöker en
                  webbplats.
                </li>
              </ul>

              <h3>2. Hur vi använder information som vi samlar in</h3>
              <p>
                Information som vi samlar in används för att tillhandahålla,
                underhålla, förbättra, skydda och förbättra Fokus
                Förskola-tjänsten samt för att utveckla nya funktioner och för
                att skydda Fokus Förskola-bolaget och våra användare.
                Informationen som vi samlar in används för att tillhandahålla
                Fokus Förskola-tjänsten, vilket inkluderar att tillhandahålla
                kundsupport samt för att förbättra användarupplevelsen och den
                övergripande kvaliteten på våra tjänster. Vi försöker ständigt
                bättre förstå hur våra användare använder Fokus Förskola och vi
                analyserar den information vi har för att utvärdera och
                förbättra våra tjänster, undersöka, utveckla och testa nya
                funktioner och genomföra felsökningsaktiviteter. Vi använder
                även din information för att svara dig när du kontaktar oss. Vi
                arbetar ständigt för att skydda Fokus Förskola och våra
                användare från obehörig åtkomst eller obehöriga ändringar som
                kan avslöja eller förstöra information som vi innehar och vi
                undersöker misstänkta aktiviteter eller kränkningar av våra
                användarvillkor eller gällande lagstiftning. Vi vidtar alla
                rimliga legala, tekniska och organisatoriska åtgärder för att
                säkerställa att din data hanteras säkert och med en adekvat
                skyddsnivå av oss eller av våra leverantörer på servrar inom EU.
              </p>

              <h3>3. Information som du och vi delar</h3>
              <p>
                Du delar dina personuppgifter och information som du skapar när
                du använder Fokus Förskola. Vi arbetar med
                tredjepartsleverantörer i syfte att tillhandahålla, förbättra,
                utvärdera, anpassa och marknadsföra Fokus Förskola-tjänsten. När
                vi delar personuppgifter och annan information med
                tredjepartsleverantörer i detta syfte, kräver vi att de använder
                din information för vår räkning i enlighet med våra anvisningar,
                denna sekretesspolicy och att alla rimliga legala, tekniska och
                organisatoriska åtgärder vidtas för att säkerställa att din data
                hanteras säkert och med en adekvat skyddsnivå vid överföring
                till eller delning med sådana utvalda tredje parter. Vi kan
                komma att lämna nödvändig information till myndigheter såsom
                polisen, skatteverket eller andra myndigheter om vi är skyldiga
                att göra det enligt lag eller om du har godkänt att vi gör det.
              </p>
              <p>
                Om Fokus Förskola-bolaget skulle involveras i en sammanslagning,
                ett förvärv eller någon annan form av försäljning kommer vi att
                säkerställa konfidentialiteten för alla personuppgifter och
                meddela berörda användare innan deras uppgifter överförs eller
                faller under någon annan sekretesspolicy.
              </p>

              <h3>4. Rättslig grund för behandlingen</h3>
              <p>
                Vi samlar in, använder och delar informationen i enlighet med
                punkterna 1-3 ovan:
              </p>
              <ul>
                <li>I syfte för att uppfylla våra användarvillkor</li>
                <li>
                  i enlighet med ditt samtycke (som du kan återkalla när som
                  helst)
                </li>
                <li>
                  i enligt vad som nödvändigt för att uppfylla våra rättsliga
                  skyldigheter
                </li>
                <li>
                  som behövs för våra (eller andras) berättigade intressen,
                  inklusive våra intressen att tillhandahålla en nyskapande,
                  relevant, säker och lönsam tjänst till våra användare och
                  partners, såvida inte dessa intressen bryter mot dina
                  intressen eller grundläggande rättigheter och friheter som
                  kräver skydd av personlig information.
                </li>
              </ul>
              <h3>5. Dina rättigheter till rättelse, radering och tillgång</h3>
              <p>
                Vi lagrar din data så länge det är nödvändigt för att
                tillhandahålla Fokus Förskola-tjänsten, eller tills ditt konto
                har raderats. Bedömningen hur länge det är nödvändigt att spara
                data utgår från faktorer som informationens art, varför den
                samlats in och bearbetats samt utifrån tillämpliga rättsliga och
                operationella lagringsbehov. Om du vill korrigera felaktiga
                eller icke-komplett information om dig själv eller ta bort din
                information med följande verktyg:
              </p>
              <ul>
                <li>
                  Profil. Genom att ändra din profil kan du ändra din
                  mailadress, profilbild, arbetsplats, samt yrkesroll.
                </li>
                <li>
                  Radera ditt Fokus Förskola-konto. Du kan när som helst ta bort
                  ditt Fokus Förskola-konto (och därigenom återkalla ditt
                  samtycke till vår användning av din information) genom att
                  skicka ett epostmeddelande till: support@edchild.com. När du
                  tar bort ditt Fokus Förskola-konto raderas din data och alla
                  andra uppgifter som vi inte längre behöver använda för att
                  tillhandahålla Fokus Förskola-tjänsten från våra servrar. Vi
                  har som mål att hålla Fokus Förskola-tjänsten i sådant skick
                  att information inte förstörs av misstag eller genom skadliga
                  processer. När du raderar ditt konto kan det därför ta ett tag
                  innan kopior av den borttagna informationen försvinner från
                  våra aktiva servrar. Dessutom kan informationen finnas kvar i
                  våra säkerhetskopieringssystem en begränsad tid.
                </li>
              </ul>
              <p>Vidare kan du även:</p>
              <ul>
                <li>
                  Få tillgång till data. Begära ut information om dina sparade
                  personuppgifter genom att skicka ett epostmeddelande till
                  support@edchild.com.
                </li>
                <li>
                  Cookies. Du kan stänga av cookies genom att ändra
                  säkerhetsinställningarna i webbläsaren och du kan också radera
                  tidigare lagrade cookies via din webbläsares inställningar.
                </li>
              </ul>
              <h3>6. Kontakta oss</h3>
              <p>
                EdChild AB är ett svenskt aktiebolag med säte på Wollmar
                Yxkullsgatan 21C, 118 50 Stockholm. EdChild AB följer svensk
                dataskyddslagstiftning och är personuppgiftansvarigt för
                behandlingen av dina personuppgifter ovan. Har du frågor
                relaterade till dataskydd kan du nå oss på support@edchild.com
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
