import axios from 'axios';

const setAuthToken = token => {
  if (token) {
    //Aply to every request
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    //Delete the auth header
    delete axios.defaults.headers.common['Authorization'];
  }
  axios.defaults.headers.common['sessionid'] = Date.now();
};

export default setAuthToken;
