import React, { Component } from "react";
import { connect } from "react-redux";
import { Feather } from "react-web-vector-icons";
import { getAllOrganisations } from "../../../actions/organisationsActions";
import { getPedagogicalAreas } from "../../../actions/pedagogyActions";
import Card from "../../common/Card";
import CardAdd from "../../common/CardAdd";
import OrganisationForm from "../forms/OrganisationForm";
import DisplayLink from "../../common/DisplayLink";
import ScaleLoader from "react-spinners/ScaleLoader";
const uuidv4 = require("uuid/v4");

class PreschoolOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrganisation: null,
    };
  }

  componentDidMount() {
    !this.props.pedagogy.pedagogicalAreas && this.props.getPedagogicalAreas();
    if (!this.props.organisations.organisations) {
      this.props
        .getAllOrganisations()
        .then(() => this.fillOrganisation())
        .catch((err) => console.log(err));
    } else {
      this.fillOrganisation();
    }
  }

  fillOrganisation() {
    const organisationId = this.props.match.params.preschoolorganisationid;
    const organisations = this.props.organisations.organisations;
    const organisation = organisations.filter((item) => {
      return item._id === organisationId;
    })[0];
    this.setState({
      currentOrganisation: organisation,
    });
  }

  render() {
    let cards;
    if (
      this.state.currentOrganisation &&
      this.state.currentOrganisation.preschools
    ) {
      cards = this.state.currentOrganisation.preschools.map((item, index) => {
        return (
          <Card
            preschool={item}
            organisationId={this.state.currentOrganisation._id}
            key={index + item._id}
            history={this.props.history}
            params={this.props.match.params}
          />
        );
      });
    }
    let cardsContainer;
    if (this.state.currentOrganisation) {
      if (!!this.state.currentOrganisation.preschools.length) {
        cardsContainer = (
          <div className="row">
            {cards}
            <CardAdd
              url={`/organisation/${this.props.match.params.preschoolorganisationid}/new`}
            />
          </div>
        );
      } else {
        cardsContainer = (
          <div style={{ width: "18rem" }}>
            {
              <CardAdd
                url={`/organisation/${this.props.match.params.preschoolorganisationid}/new`}
              />
            }{" "}
          </div>
        );
      }
    }

    let content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: window.innerHeight,
        }}
      >
        <ScaleLoader
          height={35}
          width={4}
          radius={2}
          margin={2}
          loading={true}
          color={"#FCB817"}
        />
        <div style={{ color: "#FCB817", fontSize: 12 }}>Laddar</div>
      </div>
    );
    if (
      this.state.currentOrganisation &&
      this.props.pedagogy.pedagogicalAreas
    ) {
      content = (
        <div>
          <div
            className="modal fade preschoolOrganisationModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myExtraLargeModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            id="myModal"
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <OrganisationForm
                  data={this.state.currentOrganisation}
                  action="preschoolorganisation"
                  params={this.props.match.params}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="container">
              <h1>
                {this.state.currentOrganisation.name}{" "}
                <button
                  type="button"
                  className="btn btn-outline-light btn-sm"
                  data-toggle="modal"
                  data-target=".preschoolOrganisationModal"
                >
                  <Feather name="edit" color="black" size={18} />
                </button>
              </h1>

              <div>
                {this.state.currentOrganisation.adress.map((item, index) => {
                  return (
                    <div
                      style={{ paddingBottom: "20px" }}
                      key={index + item._id}
                    >
                      <h4>{item.name}</h4>
                      <div>{item.streetadress1}</div>
                      <div>{item.streetadress2}</div>
                      <div>
                        {item.postcode} {item.city}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div>
                {this.state.currentOrganisation.phones.map((item, index) => {
                  return (
                    <div
                      style={{ paddingBottom: "20px" }}
                      key={index + item._id}
                    >
                      <h4>{item.name}</h4>
                      <div>{item.number}</div>
                    </div>
                  );
                })}
              </div>

              <div>
                {this.state.currentOrganisation.emails.map((item, index) => {
                  return (
                    <div
                      style={{ paddingBottom: "20px" }}
                      key={index + item._id}
                    >
                      <h4>{item.name}</h4>
                      <div>
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
              {cardsContainer}
              {this.state.currentOrganisation.homepage !== "" && (
                <DisplayLink
                  link={{ url: this.state.currentOrganisation.homepage }}
                />
              )}
            </div>
            <div style={{ height: "30px" }}></div>
          </div>
        </div>
      );
    }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  organisations: state.organisations,
  pedagogy: state.pedagogy,
});

const mapDispatchToProps = { getAllOrganisations, getPedagogicalAreas };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreschoolOrganisation);
