import React, { Component } from 'react';
import { connect } from 'react-redux';
import PedagogyFormModal from '../forms/PedagogyFormModal';

export class NewPedagogyItem extends Component {
  render() {
    let parentId;
    let subject = 'pedagogicalarea';
    if (this.props.match.params.centralcontentid) {
      parentId = this.props.match.params.centralcontentid;
      subject = 'milestone';
    } else if (this.props.match.params.pedagogicalareaid) {
      parentId = this.props.match.params.pedagogicalareaid;
      subject = 'centralcontent';
    }

    return (
      <div>
        <div className="container">
          <PedagogyFormModal
            subject={subject}
            parentId={parentId}
            params={this.props.match.params}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPedagogyItem);
