import React from 'react';
import TextFieldGroup from './TextFieldGroup';
import { Feather } from 'react-web-vector-icons';

export default function PhoneField({
  phone,
  index,
  onChange,
  onClick,
  disabled
}) {
  return (
    <div className="paragrafForm">
      <h4>
        Telefonnummer {index + 1}
        {index > 0 && (
          <button
            type="button"
            className="btn btn-outline-warning btn-sm"
            onClick={() => onClick(index)}
          >
            <Feather
              name="trash-2"
              size={12}
              color="#ffc107"
              className="text-warning"
            />{' '}
            Ta bord
          </button>
        )}
      </h4>
      <TextFieldGroup
        name="name"
        placeholder="Namn"
        value={phone.name}
        info="Ange ett namn, t.ex. mobiltelefon, rektor, kontor..."
        type="text"
        disabled={disabled}
        onChange={e => onChange(e, index, 'name', 'phones')}
      />
      <TextFieldGroup
        name="number"
        placeholder="Telefonnummer"
        value={phone.number}
        info="Telefonnummer"
        type="text"
        disabled={disabled}
        onChange={e => onChange(e, index, 'number', 'phones')}
      />
    </div>
  );
}
