import axios from 'axios';
import { SET_ACTIVITY_LIST, GET_ERRORS, SET_CURRENT_DISPLAY_ACTIVITY } from './types';

//Get activities
export const getAllActivities = () => dispatch => {
    axios
        .get('admin/activities')
        .then(res => dispatch({
            type: SET_ACTIVITY_LIST,
            payload: res.data
        }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const saveAge = (ageMin, ageMax, id) => dispatch => {
    axios.post('admin/updateactivityage', { ageMin: ageMin, ageMax: ageMax, id: id })
        .then(res => {
            console.log(res.data)
            dispatch(getAllActivities())
        })
        .catch(err => err)
}

//Get activity with id
export const getActivity = id => dispatch => {
    dispatch({
        type: SET_CURRENT_DISPLAY_ACTIVITY,
        payload: null
    })
    return new Promise((resolve, reject) => {
        axios
            .get('activity/singleactivity/' + id)
            .then(res => {
                dispatch({
                    type: SET_CURRENT_DISPLAY_ACTIVITY,
                    payload: res.data
                })
                resolve(res.data);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
                reject(err);
            });
    });
};