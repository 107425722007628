import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { getSurvey } from "../../actions/surveyActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Survey from "../survey/Survey";
import Spinner from "../common/Spinner";
//import Showcase from './Showcase';

class Main extends Component {
  // componentDidMount() {
  //   if (this.props.auth.isAuthenticated) {
  //     this.props.history.push('/dashboard');
  //   }
  // }

  componentDidMount() {
    this.props.getSurvey("5cbecbb45c253904eab67d3a");
  }

  render() {
    return (
      <div>
        <div className='idea'>
          <div>
            <div className='idea-inner'>
              <p>
                Visste du att det krävs ett ordförråd om ca 50 000 ord för att
                kunna läsa och förstå en vanlig artikel i en dagstidning? Idag
                kan ordförrådet hos svenska 17-åringar variera mellan 17 000 –
                70 000 ord och enligt den studie som Mats Myrberg, professor i
                specialpedagogik, genomfört är det inte slumpen som avgör.
                Grunden för ordförståelse och läsförmåga börjar redan innan ditt
                barn lärt sig gå och utvecklas sedan kontinuerligt under
                uppväxten förutsatt att dessa förmågor stimuleras. Med många års
                erfarenhet från förskolan ser vi på edChild hur detta även
                gäller för andra kunskapsområden.
              </p>

              <p>
                edChild är en mobilapp där du som förälder kan följa ditt barns
                utveckling när det passar dig. Du kan få förslag på och välja
                aktiviteter som passar i vardagspusslet, men som ändå har ett
                utvecklande innehåll. Som förälder kan du också bjuda in andra
                familjemedlemmar och dela minnen, bilder och framsteg på ett
                tryggt sätt eftersom det är du som äger och kontrollerar
                innehållet.
              </p>

              <p>
                Det är ett komplext och stort område att försöka strukturera och
                förenkla. Därför ber vi nu om hjälp med vad vi ska fokusera på i
                första hand, utifrån föräldrars perspektiv.
              </p>
            </div>
            <div className='survey-intro'>
              {this.props.survey.loading ? <Spinner /> : <Survey />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Main.propTypes = {
  survey: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  survey: state.survey
});

export default connect(
  mapStateToProps,
  { getSurvey }
)(Main);
