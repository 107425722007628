import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  getActivityStats,
  getUserStats,
  getPrivateUserStats,
} from "../../actions/statsActions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { users, activities } = useSelector((state) => state.stats);

  useEffect(() => {
    dispatch(getActivityStats());
    dispatch(getUserStats());
    dispatch(getPrivateUserStats());
  }, []);

  const filterUser = [
    "5cb037089f7a9b00172f335a",
    "5ca75c8e0f75122794daea18",
    "5cb0371d9f7a9b00172f335b",
  ];

  const doneLoading = activities && users;
  // organisations && pedagogy && !pedagogy.loading && !organisations.loading;

  return (
    <div>
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column", height: "50vmin" }}
      >
        <div>
          <h1>Admin dashboard</h1>
          <h4>Välkommen {user.name}</h4>
        </div>
        {doneLoading ? (
          <div>
            <div>Total antal användare: {users && users.length}</div>
            <div>
              Total antal aktiviteter: {activities.activities.length} (
              {`${
                activities.activities.filter((item) => item.private).length
              } privata | ${
                activities.activities.filter((item) => !item.private).length
              } publika`}
              )
            </div>
            <div>
              Total antal planerade aktiviteter:{" "}
              {activities && activities.plannedActivities.length}
            </div>
            <div style={{ marginTop: 10, fontWeight: "bold" }}>
              Top 10 användare som skapar aktiviteter
            </div>
            {users
              .filter(
                (us) =>
                  filterUser.filter((item) => item === us._id).length === 0
              )
              .sort((a, b) => b.numActivities - a.numActivities)
              .slice(0, 10)
              .map((us) => (
                <div>
                  {us.name} ({us.numActivities} aktiviteter)
                </div>
              ))}
            <div style={{ marginTop: 10, fontWeight: "bold" }}>
              Top 10 användare som planerar aktiviteter
            </div>
            {users
              .filter(
                (us) =>
                  filterUser.filter((item) => item === us._id).length === 0
              )
              .sort((a, b) => b.numPlannedActivities - a.numPlannedActivities)
              .slice(0, 10)
              .map((us) => (
                <div>
                  {us.name} ({us.numPlannedActivities} planerade aktiviteter)
                </div>
              ))}

            <div style={{ marginTop: 10, fontWeight: "bold" }}>
              Top 10 publika aktiviteter
            </div>
            {activities.activities
              .filter((act) => !act.private)
              .sort((a, b) => b.numPlan - a.numPlan)
              .slice(0, 10)
              .map((act) => (
                <div>
                  {act.name} (planerad {act.numPlan} gånger)
                </div>
              ))}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <ScaleLoader
              height={35}
              width={4}
              radius={2}
              margin={2}
              loading={true}
              color={"#FCB817"}
            />
            <div style={{ color: "#FCB817", fontSize: 12 }}>Laddar</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
