import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllActivities, saveAge } from '../../../actions/activityActions'

export default function UpdateAge() {
    const dispatch = useDispatch()
    dispatch(getAllActivities())
    const activities = useSelector(state => state.activity.allActivities)

    const [showDetail, setShowDetail] = useState()

    const toggleVisible = (id) => {
        const visibleId = showDetail
        setShowDetail(visibleId === id ? null : id)
    }

    const setAge = (ageMin, ageMax, id) => {
        dispatch(saveAge(ageMin, ageMax, id))
        setShowDetail(null)
    }


    const activitiesToUpdate = activities.map((item, index) => {
        return (
            <div key={item._id} className="alert alert-success" role="alert">
                <div className="d-flex justify-content-between w-100" role="alert" >
                    <div className="d-flex align-items-center showCursor" onClick={() => toggleVisible(index)}>{item.name} {item.loading && 'Sparar...'}</div>
                    <div >
                        <button type="button" className="btn btn-info mx-2" onClick={() => setAge(1, 3, item._id)}>1 - 3</button>
                        <button type="button" className="btn btn-info mx-2" onClick={() => setAge(3, 6, item._id)}>3 - 6</button>
                        <button type="button" className="btn btn-info mx-2" onClick={() => setAge(1, 6, item._id)}>1 - 6</button>
                    </div>
                </div >

                {showDetail === index && (<div><hr />
                    {item.pictures.length > 0 &&
                        item.pictures.map(picture => <img src={`https://api.edchild.com/${picture.small.path}`} className="img-thumbnail w-25" alt="Responsive image"></img>)
                    }
                    <div>{item.comment}</div>
                </div>)}
            </div>
        )
    })




    return (
        <div className="container py-4">
            {activities.length === 0 && <div>... laddar aktiviteter</div>}
            {activitiesToUpdate}
        </div>
    )
}
