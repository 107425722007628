import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

//Register User
export const registeruser = (userData, history) => (dispatch) => {
  axios
    .post("users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Login get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("users/login", userData)
    .then((res) => {
      //Save to localstorage
      const { token } = res.data;
      //Decode token to get user data
      const decoded = jwt_decode(token);
      if (decoded.isAdmin) {
        localStorage.setItem("jwtToken", token);
        //Set token to auth header
        setAuthToken(token);
        dispatch(setCurrentUser(decoded));
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: {
            email: "Error 403 - Forbidden",
            password: "Error 403 - Forbidden",
          },
        });
      }
      //Set current user
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Set loged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//Log user out
export const logoutUser = () => (dispatch) => {
  //Remove token from local storage
  localStorage.removeItem("jwtToken");
  //Remove auth header
  setAuthToken(false);
  //Set current user to empty
  dispatch(setCurrentUser({}));
};
