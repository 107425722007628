import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Feather } from "react-web-vector-icons";
import OrganisationForm from "../forms/OrganisationForm";
import ScaleLoader from "react-spinners/ScaleLoader";

import { getAllOrganisations } from "../../../actions/organisationsActions";
import { getPedagogicalAreas } from "../../../actions/pedagogyActions";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrganisation: null,
      selectedPreschool: null,
      selectedSection: null,
    };
  }

  componentDidMount() {
    !this.props.pedagogy.pedagogicalAreas && this.props.getPedagogicalAreas();
    if (!this.props.organisations.organisations) {
      this.props
        .getAllOrganisations()
        .then(() => this.fillOrganisation())
        .catch((err) => console.log(err));
    } else {
      this.fillOrganisation();
    }
  }

  fillOrganisation() {
    const organisationId = this.props.match.params.preschoolorganisationid;
    const preschoolId = this.props.match.params.preschoolid;
    const sectionId = this.props.match.params.sectionid;

    const organisations = this.props.organisations.organisations;

    const organisation = organisations.filter((item) => {
      return item._id === organisationId;
    })[0];

    const preschool = organisation.preschools.filter((item) => {
      return item._id === preschoolId;
    })[0];

    const section = preschool.sections.filter((item) => {
      return item._id === sectionId;
    })[0];

    this.setState({
      currentOrganisation: organisation,
      selectedPreschool: preschool,
      selectedSection: section,
    });
    console.log(this.state);
  }

  render() {
    let content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: window.innerHeight,
        }}
      >
        <ScaleLoader
          height={35}
          width={4}
          radius={2}
          margin={2}
          loading={true}
          color={"#FCB817"}
        />
        <div style={{ color: "#FCB817", fontSize: 12 }}>Laddar</div>
      </div>
    );
    const phones =
      this.state.selectedSection &&
      this.state.selectedSection.phones.map((item) => {
        return (
          <div>
            <h5>Telefon(er)</h5>
            <div style={{ fontWeight: "bold" }}>{item.name}</div>
            <div>{item.number}</div>
            <hr />
          </div>
        );
      });

    const emails =
      this.state.selectedSection &&
      this.state.selectedSection.emails.map((item) => {
        return (
          <div>
            <h5>Email</h5>
            <div style={{ fontWeight: "bold" }}>{item.name}</div>
            <div>
              <a href={"mailto:" + item.email}>{item.email}</a>
            </div>
            <hr />
          </div>
        );
      });

    if (
      this.state.currentOrganisation &&
      this.props.pedagogy.pedagogicalAreas
    ) {
      content = (
        <div>
          <div
            className="modal fade sectionModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myExtraLargeModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            id="myModal"
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <OrganisationForm
                  data={this.state.selectedSection}
                  action="section"
                  params={this.props.match.params}
                  history={this.props.history}
                  parentId={this.state.selectedPreschool._id}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <h1>
              {this.state.currentOrganisation &&
                this.state.currentOrganisation.name}
              {" - "}
              {this.state.selectedPreschool &&
                this.state.selectedPreschool.name}{" "}
            </h1>

            <h4>
              Avdelning{" "}
              {this.state.selectedSection && this.state.selectedSection.name}{" "}
              <button
                type="button"
                className="btn btn-outline-light btn-sm"
                data-toggle="modal"
                data-target=".sectionModal"
              >
                <Feather name="edit" color="black" size={16} />
              </button>
            </h4>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ flex: 1, flexDirection: "column" }}>
                {phones}
                {emails}
                <h6>Pedagoger</h6>
                <Feather name="user-plus" color="black" size={14} />{" "}
                <Link to={`/organisation/new`}>Skapa en anställning</Link>
                <h6>Barnen</h6>
                <Feather name="user-plus" color="black" size={14} />{" "}
                <Link to={`/organisation/new`}>Registrera ett barn</Link>
              </div>
              <div style={{ flex: 1, flexDirection: "column" }}></div>
            </div>
          </div>
        </div>
      );
    }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  organisations: state.organisations,
  pedagogy: state.pedagogy,
});

const mapDispatchToProps = { getAllOrganisations, getPedagogicalAreas };

export default connect(mapStateToProps, mapDispatchToProps)(Section);
