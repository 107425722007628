import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class TextMultipleQuestionField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textFields: ['']
    };
  }

  addTextField = () => {
    this.setState(prevState => ({
      textFields: [...prevState.textFields, '']
    }));
  };

  valueChangedHandler = async e => {
    const id = e.target.id;
    const value = e.target.value;

    await this.setState(prevState => ({
      textFields: prevState.textFields.map((item, index) => {
        if (Number(index) === Number(id)) {
          return value;
        } else {
          return item;
        }
      })
    }));
    const item = {
      target: {
        id: this.props.id,
        value: this.state.textFields
      }
    };
    this.props.onChange(item);
  };

  render() {
    let textFields = this.state.textFields.map((item, index) => {
      return (
        <div className="row" key={index}>
          <input
            type={this.props.type}
            className={classnames('form-control col-md-3', {
              'is-invalid': this.props.error ? this.props.error : null
            })}
            id={index}
            aria-describedby="question"
            placeholder={
              this.props.placeHolder ? this.props.placeHolder : 'Ditt svar'
            }
            value={this.state.textFields[index]}
            onChange={this.valueChangedHandler}
          />
          {this.props.error && (
            <div className="invalid-feedback">{this.props.error}</div>
          )}
        </div>
      );
    });
    return (
      <div key={this.props.id}>
        <div className="form-group">
          <label htmlFor={this.props.id}>{this.props.question}</label>
          {textFields}
        </div>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={this.addTextField}
        >
          + Lägg till barn
        </button>
      </div>
    );
  }
}

TextMultipleQuestionField.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  error: PropTypes.object,
  question: PropTypes.string.isRequired,
  placeHolder: PropTypes.string
};

export default TextMultipleQuestionField;
