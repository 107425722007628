import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Feather } from "react-web-vector-icons";
import PedagogyFormModal from "../forms/PedagogyFormModal";
import DisplayLink from "../../common/DisplayLink";
import axios from "axios";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ScaleLoader from "react-spinners/ScaleLoader";

const uuidv4 = require("uuid/v4");

const PedagogicalArea = ({ id, pedagogicalAreas }) => {
  const [pedagogicalAreaStats, setPedagogicalAreaStats] = useState(null);
  let pedagogycalArea;

  pedagogycalArea = pedagogicalAreas.filter((item) => {
    return item._id === id;
  })[0];

  useEffect(() => {
    axios
      .get(`/v2/metrics/JnMBaLqRHgYyHPIEHCbhJAJJBcTCjEtV/activities/${id}`)
      .then((res) => {
        const startDate = res.data.activities[0].createddate;
        let chartData = [];
        const allActivities = res.data.activities;
        const allPlannedActivities = res.data.plannedActivities;
        let activitiesCount = 0;
        let plannedActivitiesCount = 0;

        const filterByDate = (act, month) => {
          const start = moment(month).startOf("month").format("YYYYMMDD");
          const end = moment(month).endOf("month").format("YYYYMMDD");

          return act.filter((a) => {
            const actDay = moment(a.createddate).format("YYYYMMDD");
            if (actDay >= start && actDay <= end) {
              return true;
            } else {
              return false;
            }
          });
        };

        for (
          let month = moment(startDate).endOf("month").format("YYYY-MM-DD");
          month <= moment().endOf("month").format("YYYY-MM-DD");
          month = moment(month).add(1, "month").format("YYYY-MM-DD")
        ) {
          const activities = filterByDate(allActivities, month).length;
          const plannedActivities = filterByDate(allPlannedActivities, month)
            .length;
          activitiesCount += activities;

          plannedActivitiesCount += plannedActivities;
          chartData.push({
            name: moment(month).format("MMM YYYY"),
            "Totalantal planerade aktiviteter": plannedActivitiesCount,
            "Totalantal aktiviteter": activitiesCount,
            "Planerade aktiviteter": plannedActivities,
            Aktiviteter: activities,
          });
        }
        setPedagogicalAreaStats(chartData);
      })
      .catch((err) => console.log(err));
  }, []);

  const pedagogicalAreaContent = pedagogycalArea.content.map((item) => {
    const title = item.title;
    const text = item.text;
    return (
      <div key={uuidv4()}>
        <h4>{title}</h4>
        <p style={{ whiteSpace: "pre-line" }}>{text}</p>
      </div>
    );
  });

  const curriculumAreas = pedagogycalArea.lpfoTargets.map((item) => {
    return <li key={uuidv4()}>{item.description}</li>;
  });

  const links = pedagogycalArea.links.map((item) => {
    return <DisplayLink link={item} key={uuidv4()} />;
  });

  let pedagogicalAreaCentralContents = pedagogycalArea.centralcontent.map(
    (item) => {
      const name = item.name;

      return (
        <li key={uuidv4()}>
          <Link
            to={`/pedagogicalarea/${id}/${item._id}`}
            className="inactiveContent"
          >
            {name}
          </Link>
        </li>
      );
    }
  );

  let locales = pedagogycalArea.locales
    .filter((lang) => lang.locale.languageCode !== "sv")
    .map((lang) => {
      const content = lang.content.map((item) => {
        const title = item.title;
        const text = item.text;
        return (
          <div key={uuidv4()}>
            <h4>{title}</h4>
            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
          </div>
        );
      });

      return (
        <div style={{ backgroundColor: "whitesmoke" }} key={uuidv4()}>
          <h2 className="adminSubTitle">
            Översättning på {lang.locale.name} (Kod "{lang.locale.languageCode}
            ")
          </h2>
          <div style={{ padding: 12 }}>
            <h3>{lang.name}</h3>
            {content}
            <h4>Kort beskrivning</h4>
            <p style={{ whiteSpace: "pre-line" }}>{lang.comment}</p>
          </div>
        </div>
      );
    });

  const chart = pedagogicalAreaStats ? (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={500}
        height={300}
        data={pedagogicalAreaStats}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Totalantal planerade aktiviteter"
          stroke="#FC663D"
        />
        <Line
          type="monotone"
          dataKey="Totalantal aktiviteter"
          stroke="#28AA24"
        />
        <Line
          type="monotone"
          dataKey="Planerade aktiviteter"
          stroke="#64A4DD"
        />
        <Line
          type="monotone"
          dataKey="Aktiviteter"
          stroke="#DBCE65"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: 500,
      }}
    >
      <ScaleLoader
        height={35}
        width={4}
        radius={2}
        margin={2}
        loading={true}
        color={"#FCB817"}
      />
    </div>
  );

  return (
    <div>
      <div
        className="modal fade bd-example-modal-xl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        id="myModal"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <PedagogyFormModal
              content={pedagogycalArea}
              subject="pedagogicalarea"
              modalName="myModal"
            />
          </div>
        </div>
      </div>

      <img
        src={pedagogycalArea.header}
        className="img-fluid"
        alt={pedagogycalArea.name}
      />

      <h1 className="adminTitle">
        Läroplansområde: {pedagogycalArea.name}{" "}
        <button
          type="button"
          className="btn btn-outline-light btn-sm"
          data-toggle="modal"
          data-target=".bd-example-modal-xl"
        >
          <Feather name="edit" color="aliceblue" size={18} />
        </button>
      </h1>
      <div style={{ marginTop: 30, marginBottom: 30 }}>{chart}</div>
      {pedagogicalAreaContent}
      <h2 className="adminSubTitle">Beskrivning (kort)</h2>
      <p style={{ whiteSpace: "pre-line" }}>{pedagogycalArea.comment}</p>
      <h2 className="adminSubTitle">Läroplansområden</h2>
      <ul>{curriculumAreas}</ul>
      {!!pedagogycalArea.links.length && (
        <div>
          <h2 className="adminSubTitle">Länkar</h2>
          {links}
        </div>
      )}
      {locales}
      <h2 className="adminSubTitle">Kopplade Centrala innehål</h2>
      <ul>{pedagogicalAreaCentralContents}</ul>
      <Link className="dropdown-item" to={`/pedagogicalarea/${id}/new`}>
        <Feather
          name="plus-square"
          color="black"
          size={14}
          // style={{}}
        />{" "}
        Nytt centralt innehål
      </Link>
      <div style={{ height: "20px" }}></div>
    </div>
  );
};

export default PedagogicalArea;
