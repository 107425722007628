import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class ChooseOneQuestionField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freeTextInputValue: '',
      disabled: true
    };
  }

  freeTextInputOnChgangeHandler = (e, value) => {
    this.setState({ freeTextInputValue: e.target.value });
    const answer = {
      target: {
        value: value + ';' + e.target.value,
        id: this.props.id
      }
    };
    this.props.onChange(answer);
  };

  onChangeSelectedHandler = (e, other) => {
    if (other === 1) {
      this.setState({ disabled: false });
      this.props.onChange(e);
    } else {
      this.setState({ disabled: true });
      this.props.onChange(e);
    }
  };

  render() {
    const questionOptions = this.props.options
      .sort(function(a, b) {
        return b.value - a.value;
      })
      .map(option => {
        let textField;
        if (option.other) {
          textField = (
            <input
              type="text"
              id={this.props.id}
              value={this.state.freeTextInputValue}
              className={classnames('form-control', {
                'is-invalid': this.props.error ? this.props.error : null
              })}
              onChange={e => {
                this.freeTextInputOnChgangeHandler(e, option.value);
              }}
              disabled={this.state.disabled}
            />
          );
        }

        return (
          <div className="row" key={option.value}>
            <div className="form-check col-md-6">
              <input
                className="form-check-input"
                type="radio"
                id={this.props.id}
                name={this.props.id + option.option}
                value={option.value}
                checked={
                  this.props.value.split(';')[0].toString() ===
                  option.value.toString()
                }
                onChange={e => {
                  this.onChangeSelectedHandler(e, option.other ? 1 : 0);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={this.props.id + option.option}
              >
                {option.option}
              </label>
            </div>
            <div className="col-md-6">{textField}</div>
          </div>
        );
      });
    return (
      <div>
        <div className="form-group">
          <label htmlFor={this.props.id}>{this.props.question}</label>
          <div
            className={classnames({
              error: this.props.error
            })}
          >
            {questionOptions}
          </div>
          {this.props.error && (
            <div className="error-feedback">{this.props.error}</div>
          )}
        </div>
      </div>
    );
  }
}

ChooseOneQuestionField.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  options: PropTypes.array.isRequired
};

export default ChooseOneQuestionField;
