import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrganisationStats } from "../../../actions/organisationsActions";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function PreschoolDetail() {
  const dispatch = useDispatch();
  const { preschoolid, preschoolorganisationid } = useParams();

  const [organisation, setOrganisation] = useState(null);
  const [stats, setStats] = useState(null);

  const [preschool, setPreschool] = useState(null);

  const organisations = useSelector((state) => state.organisations);

  useEffect(() => {
    if (organisations.organisations !== organisation) {
      setOrganisation(organisations.organisations);
      dispatch(getOrganisationStats(preschoolid));
    }
    if (organisations.stats !== stats) {
      setStats(organisations.stats);
    }
  }, [organisations]);

  useEffect(() => {
    if (organisation) {
      organisation.map((org) => {
        org.preschools.map((item) => {
          if (item._id === preschoolid) {
            setPreschool(item);
          }
        });
      });
    }
  }, [organisation]);

  const sortPlannedActivities = (plannedActivities) => {
    console.log(plannedActivities);
    const sorted = plannedActivities.sort((a, b) =>
      a.plannedDate > b.plannedDate ? -1 : a.plannedDate < b.plannedDate ? 1 : 0
    );
    return sorted;
  };

  const sortWeeklyEvaluations = (evaluations) => {
    const sorted = evaluations.sort((a, b) =>
      a.dateTo > b.dateTo ? -1 : a.dateTo < b.dateTo ? 1 : 0
    );
    return sorted;
  };

  const renderStats =
    stats &&
    stats.map((section) => {
      return (
        <div key={section._id}>
          <h2>
            {section.name} ({section._id})
          </h2>
          <h4>Personal</h4>
          {section.employees.map((employee) => {
            return (
              <div key={employee._id}>
                {employee.name} ({employee._id}) - (
                <a href={`"mailto:${employee.email}"`}>{employee.email}</a>)
              </div>
            );
          })}
          <ul>
            <li>
              Antal planerade aktiviteter: {section.plannedActivities.length}
            </li>
            <li>
              Senast planeringstillfälle:{" "}
              {section.plannedActivities.length > 0
                ? sortPlannedActivities(
                    section.plannedActivities
                  )[0].createddate.substring(0, 10)
                : "Ingen aktivitet har planerats"}
            </li>
            <li>
              Antal genomförda veckoutvärderingar:{" "}
              {section.weeklyevaluation.length}
            </li>
            <li>
              Senast utvärderad period:{" "}
              {section.weeklyevaluation.length > 0
                ? sortWeeklyEvaluations(
                    section.weeklyevaluation
                  )[0].dateFrom.substring(0, 10) +
                  " till " +
                  sortWeeklyEvaluations(
                    section.weeklyevaluation
                  )[0].dateTo.substring(0, 10)
                : "Ingen utvärdering har skett"}
            </li>
          </ul>
        </div>
      );
    });
  const renderOutput = preschool && (
    <div>
      <h1>
        {preschool.name} (id: {preschool._id})
      </h1>
      {stats && renderStats}
    </div>
  );

  return (
    <div className="container">
      {preschool ? (
        renderOutput
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: window.innerHeight,
          }}
        >
          <ScaleLoader
            height={35}
            width={4}
            radius={2}
            margin={2}
            loading={true}
            color={"#FCB817"}
          />
          <div style={{ color: "#FCB817", fontSize: 12 }}>Laddar</div>
        </div>
      )}
    </div>
  );
}
