import React from "react";
import { Link } from "react-router-dom";
import { Feather } from "react-web-vector-icons";
import OrganisationForm from "../admin/forms/OrganisationForm";
const uuidv4 = require("uuid/v4");

export default function Card({ preschool, organisationId, params, history }) {
  const sections = preschool.sections.map((item, index) => {
    return (
      <li className='list-group-item border-success' key={item._id + index}>
        <Link
          className='nav-link'
          to={`/organisation/${organisationId}/${preschool._id}/${item._id}`}
        >
          Avdelning {item.name}
        </Link>
      </li>
    );
  });

  const adresses = preschool.adress.map((item) => {
    return (
      <div key={new uuidv4()}>
        <h6>{item.name}</h6>
        <div className='card-text'>
          <div>{item.streetadress1}</div>
          {!!item.streetadress2 && <div>{item.streetadress2}</div>}
          <div>
            {item.postcode} {item.city}
          </div>
        </div>
      </div>
    );
  });

  const phones = preschool.phones.map((item) => {
    return (
      <div key={new uuidv4()}>
        <h6>{item.name}</h6>
        <div className='card-text'>{item.number}</div>
      </div>
    );
  });

  const emails = preschool.emails.map((item) => {
    return (
      <div key={new uuidv4()}>
        <h6>{item.name}</h6>
        <div className='card-text'>
          <a href={"mailto:" + item.email}>{item.email}</a>
        </div>
      </div>
    );
  });

  return (
    <div className='col-auto mb-3'>
      <div
        className='modal fade preschoolModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='myExtraLargeModalLabel'
        aria-hidden='true'
        data-backdrop='static'
        id='myModal'
      >
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <OrganisationForm
              data={preschool}
              action='preschool'
              params={params}
              history={history}
              parentId={organisationId}
            />
          </div>
        </div>
      </div>

      <div className='card border-primary' style={{ width: "18rem" }}>
        <div className='card-header align-middle bg-primary'>
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Link
              to={`/organisation/${organisationId}/${preschool._id}`}
              style={{
                color: "white",
              }}
            >
              {preschool.name}
            </Link>
            {/*
              <button
                type='button'
                className='btn btn-outline-light btn-sm'
                data-toggle='modal'
                data-target='.preschoolModal'
              >
                <Feather name='edit' color='white' size={14} />
              </button>
            */}
          </div>
        </div>
        <div className='card-body'>
          {!!adresses ? adresses : "Ingen adress"}
          <hr />
          {!!preschool.phones.length &&
          (preschool.phones[0].name !== "" || preschool.phones[0].number !== "")
            ? phones
            : "Inget telefonnummer"}
          <hr />
          {!!preschool.emails.length &&
          (preschool.emails[0].name !== "" || preschool.emails[0].email !== "")
            ? emails
            : "Ingen mailadress"}
        </div>
        <ul className='list-group list-group-flush border-success'>
          {sections}
          <li className='list-group-item border-success'>
            <Feather name='plus-square' color='black' size={14} />{" "}
            <Link to={`/organisation/${organisationId}/${preschool._id}/new`}>
              Lägg till en avdelning
            </Link>
          </li>
          <li className='list-group-item border-success bg-success'>
            <Feather name='user-plus' color='white' size={14} />{" "}
            <Link
              to={`/organisation/${organisationId}/${preschool._id}/new`}
              style={{ color: "white" }}
            >
              Skapa en anställning
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
