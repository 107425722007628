import React, { Component } from "react";
import { connect } from "react-redux";
import { savePedagogicalArea } from "../../../actions/pedagogyActions";
import Paragraf from "../../common/Paragraf";
import Links from "../../common/Links";
import TextFieldGroup from "../../common/TextFieldGroup";
import { Feather } from "react-web-vector-icons";
import Icon from "react-web-vector-icons";

export class PedagogyFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: null,
      data: {},
      dataBacup: {},
      subject: null,
      parentId: null,
    };
  }

  componentDidMount() {
    if (!!this.props.content) {
      this.setState((prevState, props) => ({
        data: this.props.content,
        dataBacup: JSON.parse(JSON.stringify(this.props.content)),
        subject: this.props.subject,
        parentId: this.props.parentId,
      }));
    } else {
      this.setState((prevState, props) => ({
        data: {},
        //dataBacup: JSON.parse(JSON.stringify(this.props.content)),
        subject: this.props.subject,
        parentId: this.props.parentId,
      }));
    }
  }

  onChangeTitle = (e) => {
    const title = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        name: title,
      },
    }));
  };

  onChangeLocalizedTitle = (e, index) => {
    const title = e.target.value;
    let locales = [...this.state.data.locales];
    locales[index].name = e.target.value;

    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        locales: locales,
      },
    }));
  };

  onChangeLocalizedShortName = (e, index) => {
    const shortName = e.target.value;
    let locales = [...this.state.data.locales];
    locales[index].shortName = e.target.value;

    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        locales: locales,
      },
    }));
  };

  onChangeShortName = (e) => {
    const text = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        shortName: text,
      },
    }));
  };

  onChangeShortDescription = (e) => {
    const text = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        comment: text,
      },
    }));
  };

  onChangeLocalizedShortDescription = (e, index) => {
    const title = e.target.value;
    let locales = [...this.state.data.locales];
    locales[index].comment = e.target.value;

    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        locales: locales,
      },
    }));
  };

  onReset = () => {
    const bacup = JSON.parse(JSON.stringify(this.state.dataBacup));

    this.setState((prevState, props) => ({
      data: bacup,
    }));
  };

  onChangeUrl = (e) => {
    const url = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        header: url,
      },
    }));
  };

  onChangeParagraf = (e, index, TitleOrText) => {
    const paragrafs = [...this.state.data.content];
    paragrafs[index][TitleOrText] = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        content: paragrafs,
      },
    }));
  };

  onChangeLocalizedParagraf = (e, contentIndex, TitleOrText, index) => {
    let locales = [...this.state.data.locales];
    let content = locales[index].content;
    content[contentIndex][TitleOrText] = e.target.value;

    locales[index] = {
      ...locales[index],
      content: content,
    };

    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        locales: locales,
      },
    }));
    console.log(this.state.data);
  };

  onChangeIcon = (e) => {
    const icon = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        picture: icon,
      },
    }));
  };

  onRemoveParagraf = (index) => {
    const content = [...this.state.data.content];
    content.splice(index, 1);
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        content: content,
      },
    }));
  };

  onAddParagraf = () => {
    const item = {
      title: "",
      text: "",
    };
    const content = this.state.data.content ? [...this.state.data.content] : [];
    content.push(item);
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        content: content,
      },
    }));
  };

  onChangeLink = (e, index, element) => {
    const links = [...this.state.data.links];
    links[index][element] = e.target.value;
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        links: links,
      },
    }));
  };

  onAddLink = () => {
    const item = {
      name: "",
      description: "",
      url: "",
    };
    const links = this.state.data.links ? [...this.state.data.links] : [];
    links.push(item);
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        links: links,
      },
    }));
  };

  onRemoveLink = (index) => {
    const links = [...this.state.data.links];
    links.splice(index, 1);
    this.setState((prevState, props) => ({
      data: {
        ...prevState.data,
        links: links,
      },
    }));
  };

  closeOneModal = (modalId) => {
    // get modal
    const modal = document.getElementById(modalId);

    // change state like in hidden modal
    modal.classList.remove("show");
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("style", "display: none");

    // get modal backdrop
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");

    // remove opened modal backdrop
    document.body.removeChild(modalBackdrops[0]);
    document.body.classList.remove("modal-open");

    modal.setAttribute("modal", "hide");
  };

  onSubmit(e) {
    e.preventDefault();

    this.props
      .savePedagogicalArea(
        this.state.data,
        this.state.parentId,
        this.props.subject
      )
      .then((result) => {
        if (!this.props.content) {
          let routeToGo;
          const params = this.props.params;

          switch (this.props.subject) {
            case "pedagogicalarea":
              routeToGo = `/pedagogicalarea/${result._id}`;
              break;
            case "centralcontent":
              routeToGo = `/pedagogicalarea/${params.pedagogicalareaid}/${result._id}`;
              break;

            case "milestone":
              routeToGo = `/pedagogicalarea/${params.pedagogicalareaid}/${params.centralcontentid}/${result._id}`;
              break;

            default:
              routeToGo = `/pedagogicalarea`;
              break;
          }
          this.props.history.push(routeToGo);
        }
      });
    this.props.content && this.closeOneModal(this.props.modalName);
  }

  render() {
    let paragrafs = (
      <Paragraf
        title=""
        text=""
        onChange={this.onChangeParagraf}
        paragrafIndex={0}
      />
    );

    paragrafs =
      !!this.state.data.content && this.state.data.content.length > 0 ? (
        this.state.data.content.map((item, index) => (
          <Paragraf
            title={item.title ? item.title : ""}
            text={item.text}
            paragrafIndex={index}
            onChange={this.onChangeParagraf}
            onClick={this.onRemoveParagraf}
            key={`x${index}`}
          />
        ))
      ) : (
        <div>Ingen beskrivning</div>
      );

    const links =
      !!this.state.data.links && this.state.data.links.length > 0 ? (
        this.state.data.links.map((item, index) => (
          <div key={`y${index}`}>
            <Links
              linkIndex={index}
              name={item.name ? item.name : ""}
              url={item.url ? item.url : ""}
              description={item.description ? item.description : ""}
              onChange={this.onChangeLink}
              onClick={this.onRemoveLink}
            />
          </div>
        ))
      ) : (
        <div>Inga länkar</div>
      );

    const shortDescription = (
      <div>
        <h1 className="adminTitle">Kort beskrivning</h1>
        <div className="container bg-light py-2 rounded">
          <textarea
            className="form-control form-control-lg"
            placeholder="Text"
            rows="3"
            value={this.state.data.comment}
            onChange={this.onChangeShortDescription}
          ></textarea>
        </div>
      </div>
    );

    const localeForm =
      !!this.state.data.locales &&
      this.state.data.locales.map((lang, index) => {
        return (
          lang.locale.languageCode !== "sv" && (
            <div
              style={{ backgroundColor: "whitesmoke", padding: 12 }}
              key={`ditFormLang${lang._id}`}
            >
              <h2>{lang.locale.name}</h2>
              <div>
                <div className="adminSubTitle mb-2">Namn</div>
                <TextFieldGroup
                  name="namn"
                  placeholder="Namn"
                  value={lang.name}
                  info=""
                  type="text"
                  disabled={this.state.disabled}
                  onChange={(e) => this.onChangeLocalizedTitle(e, index)}
                />
              </div>
              {lang.shortName && lang.shortName != "undefined" && (
                <div>
                  <div className="adminSubTitle mb-2">Kort namn</div>
                  <TextFieldGroup
                    name="kortnamn"
                    placeholder="Kort namn"
                    value={lang.shortName}
                    info=""
                    type="text"
                    disabled={this.state.disabled}
                    onChange={(e) => this.onChangeLocalizedShortName(e, index)}
                  />
                </div>
              )}
              <div>
                <h1 className="adminSubTitle">Kort beskrivning</h1>
                <div className="bg-light py-2 rounded">
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Text"
                    rows="3"
                    value={lang.comment}
                    onChange={(e) =>
                      this.onChangeLocalizedShortDescription(e, index)
                    }
                  ></textarea>
                </div>
              </div>
              {lang.content.map((item, contentIndex) => {
                return (
                  <Paragraf
                    localeIndex={index}
                    title={item.title ? item.title : ""}
                    text={item.text}
                    paragrafIndex={contentIndex}
                    onChange={this.onChangeLocalizedParagraf}
                    onClick={() => console.log("clicked")}
                    key={`par${lang._id}${contentIndex}`}
                    type="localized"
                  />
                );
              })}
            </div>
          )
        );
      });

    // const lpfoTargets = (
    //   <div>
    //     <h1 className="adminTitle">Lpfö mål</h1>
    //     <div className="container bg-light py-2 rounded">
    //       <textarea
    //         className="form-control form-control-lg"
    //         placeholder="Text"
    //         value={this.state.data.comment}
    //         onChange={null}
    //       ></textarea>
    //     </div>
    //   </div>
    // );

    const headerPicture = (
      <div>
        <div className="adminTitle">Huvudbild</div>
        <img
          src={this.state.data.header}
          className="img-fluid mb-2"
          alt={this.state.data.header}
        ></img>
        <TextFieldGroup
          name="picture"
          placeholder="Länk till huvudbild"
          value={this.state.data.header ? this.state.data.header : ""}
          info="Länk till huvudbild"
          type="text"
          disabled={this.state.disabled}
          onChange={(e) => this.onChangeUrl(e)}
        />
      </div>
    );

    let icon;

    if (
      this.state.data.picture &&
      this.state.data.picture.split(";").length === 2
    ) {
      icon = (
        <Icon
          name={this.state.data.picture.split(";")[1]}
          font={this.state.data.picture.split(";")[0]}
          color="white"
          size={22}
          // style={{}}
        />
      );
    }

    let header = (
      <div className="modal-header">
        <h5 className="modal-title">Redigera {this.state.data.name}</h5>
      </div>
    );

    if (!this.props.content) {
      header = (
        <div>
          <h1>Ny</h1>
        </div>
      );
    }

    return (
      <div>
        {header}
        <div style={{ padding: "20px" }}>
          <form onSubmit={(e) => this.onSubmit(e)}>
            {this.state.subject === "pedagogicalarea" && headerPicture}
            <div className="adminTitle mb-2">Namn</div>
            <TextFieldGroup
              name="namn"
              placeholder="Namn"
              value={this.state.data.name ? this.state.data.name : ""}
              info=""
              type="text"
              disabled={this.state.disabled}
              onChange={(e) => this.onChangeTitle(e)}
            />
            <div className="adminTitle mb-2">Kort namn</div>
            <TextFieldGroup
              name="kort namn"
              placeholder="Kort namn"
              value={this.state.data.shortName ? this.state.data.shortName : ""}
              info=""
              type="text"
              disabled={this.state.disabled}
              onChange={(e) => this.onChangeShortName(e)}
            />
            <div className="adminTitle  mb-2">
              {icon} Icon (
              <a
                href="https://oblador.github.io/react-native-vector-icons/"
                target="_blank"
                rel="noopener noreferrer"
              >
                listan
              </a>
              )
            </div>
            <TextFieldGroup
              name="Icon"
              placeholder="Ikon"
              value={this.state.data.picture ? this.state.data.picture : ""}
              info="Ikon. OBS! Format är Family;ikonnamn (t.exempel AntDesign;wechat). Följ länken</a> för ikon listan."
              type="text"
              disabled={this.state.disabled}
              onChange={(e) => this.onChangeIcon(e)}
            />

            <div className="adminTitle">
              {this.state.subject !== "pedagogicalArea"
                ? "Lång beskrivning"
                : "Beskrivning"}
            </div>
            <div className="container bg-light pb-2 rounded">
              {paragrafs}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.onAddParagraf()}
              >
                <Feather name="plus-square" color="white" size={12} /> Lägg till
                en stycke
              </button>
            </div>
            <div>{shortDescription}</div>
            <div className="adminTitle">Länkar</div>
            <div className="container bg-light pb-2 rounded">
              {links}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.onAddLink()}
              >
                <Feather name="plus-square" color="white" size={12} /> Lägg till
                en länk
              </button>
            </div>

            {/* Translation */}
            <div className="adminTitle">Översättningar</div>
            {localeForm}
            {/* Translation */}

            <div className="py-2 row">
              <div className="col-lg-8 ml-auto">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm btn-block"
                >
                  <Feather name="save" color="white" size={12} /> Spara
                </button>
              </div>
              <div className="col-lg-2 ml-auto">
                <button
                  type="button"
                  className="btn btn-warning btn-sm btn-block"
                  onClick={() => {
                    this.onReset();
                  }}
                >
                  <Feather name="x-square" color="white" size={12} /> Återställ
                </button>
              </div>
              <div className="col-lg-2 ml-auto">
                <button
                  data-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-danger btn-sm btn-block"
                  id="closeBtn"
                >
                  <Feather name="x-square" color="white" size={12} /> Avbryt
                </button>
              </div>
            </div>
          </form>
        </div>
        <div style={{ height: "20px" }}></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { savePedagogicalArea };

export default connect(mapStateToProps, mapDispatchToProps)(PedagogyFormModal);
