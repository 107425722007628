import React, { Component } from 'react';

class Support extends Component {
  render() {
    return (
      <div>
        <h1 className="display-4 text-center">Support</h1>
        <p className="lead text-center">
          För att kontakta supporten kan du skicka ett mail till
          support@edchild.com
        </p>
      </div>
    );
  }
}

export default Support;
