import axios from "axios";
import {
  GET_ERRORS,
  SET_ACTIVITY_STATS,
  SET_USER_STATS,
  SET_PRIVATE_USER_STATS,
} from "./types";

export const getActivityStats = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v2/metrics/JnMBaLqRHgYyHPIEHCbhJAJJBcTCjEtV/activities")
      .then((res) => {
        dispatch({ type: SET_ACTIVITY_STATS, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response,
        });
        reject(err);
      });
  });
};

export const getUserStats = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v2/metrics/JnMBaLqRHgYyHPIEHCbhJAJJBcTCjEtV/users")
      .then((res) => {
        dispatch({ type: SET_USER_STATS, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getPrivateUserStats = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v2/admin/allusers/5e31caf61af998004cba0d69")
      .then((res) => {
        dispatch({ type: SET_PRIVATE_USER_STATS, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
